import { useMemo, useState } from 'react'
import download from 'downloadjs'

import { ListBulletIcon, PrinterIcon, QueueListIcon, TrashIcon } from '@heroicons/react/24/outline'
import Table from 'components/ui/Table'
import { Button, Card, Checkbox } from 'components/gsys-ui'
import haxios from 'util/axios'

const SelectedPartsList = ({ selectedParts, deselectPart, clearParts }) => {
  const [isLoadingBarcode, setLoadingBarcode] = useState(false)
  const [{ pageIndex, pageSize }, setPagination] = useState({ pageIndex: 0, pageSize: 10 })

  const pagedData = selectedParts.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)

  const downloadBarcodePdf = async () => {
    if (selectedParts.length === 0) return
    setLoadingBarcode(true)
    const codes = selectedParts.map((part) => part.id)
    const pdf = await haxios.post('/parts/barcodes', { codes }, { responseType: 'blob' })
    download(pdf, 'barcodes.pdf', 'application/pdf')
    setLoadingBarcode(false)
  }

  return (
    <Card>
      <Card.Title Icon={ListBulletIcon}>
        <div>Selected parts</div>
        <Button
          onClick={downloadBarcodePdf}
          loading={isLoadingBarcode}
          disabled={selectedParts.length === 0}
        >
          <PrinterIcon className="mr-1 w-5 h-5" />Print
        </Button>
      </Card.Title>
      <Card.Body>
        <SelectedPartsTable
          data={pagedData}
          currentPage={pageIndex}
          onPageChange={setPagination}
          totalRowsCount={selectedParts.length}
          selectedParts={selectedParts}
          deselectPart={deselectPart}
        />
        <div className="flex justify-end pt-2">
          <Button
            variant="white"
            onClick={clearParts}
            disabled={selectedParts.length === 0}
          >
            <TrashIcon className="mr-1 w-5 h-5" />Clear all
          </Button>
        </div>
      </Card.Body>
    </Card>
  )
}

const SelectedPartsTable = (props) => {
  const columns = useMemo(() => [
    {
      header: '',
      id: 'checkbox',
      cellSize: 20,
      cellPad: false,
      cell: ({ row }) => (
        <Checkbox
          checked={true}
          onClick={() => props.deselectPart(props.selectedParts.filter((p) => p.id === row.original.id).pop())}
        />
      )
    },
    {
      header: 'Code',
      accessorKey: 'code',
      cellSize: 1,
      noWrap: true
    },
    {
      header: 'Name',
      accessorKey: 'name',
      truncate: true
    }
  ])

  const data = useMemo(() => props.data, [props.data])

  return (
    <Table
      cols={columns}
      data={data}
      isPaginated={true}
      currentPage={props.currentPage}
      onPageChange={props.onPageChange}
      totalRowsCount={props.totalRowsCount}
    />
  )
}

export default SelectedPartsList