import { useContext, useMemo, useState } from 'react'

import { ModalContext } from 'context/ModalContext'
import AddClientModal from 'components/clients/AddClientModal'
import { PlusIcon, UsersIcon } from '@heroicons/react/24/outline'
import Table from 'components/ui/Table'
import { Card, Button, Dropdown } from 'components/gsys-ui'
import { useClients } from 'util/queries'

const ClientsList = () => {
  const [{ pageIndex, pageSize }, setPagination] = useState({ pageIndex: 0, pageSize: 10 })
  const [filterLetter, setFilterLetter] = useState(null)
  const modal = useContext(ModalContext)
  const { isLoading, isError, data, error } = useClients(null, filterLetter?.value, pageIndex)
  const azCharCodes = Array.from({ length: 26 }, (_, ind) => ind + 65)
  const azSelectOpt = [
    { value: null, label: 'All' },
    ...azCharCodes.map((n) => ({ value: String.fromCharCode(n), label: String.fromCharCode(n) }))
  ]

  const changeLetter = (v) => {
    setPagination({ pageIndex: 0, pageSize: 10 })
    setFilterLetter(v)
  }

  if (isLoading) return null
  if (isError) return <div>{JSON.stringify(error)}</div>
  if (data.error) return <div>{JSON.stringify(data)}</div>

  return (
    <Card>
      <Card.Title Icon={UsersIcon}>
        <div>All clients</div>
        <div className="flex items-center space-x-2">
          <Dropdown
            style={{ width: '90px', fontSize: '12px' }}
            options={azSelectOpt}
            value={filterLetter}
            onChange={changeLetter}
          />
          <Button onClick={() => modal.set(<AddClientModal initial={{ userId: 1 }} />)}>
            <PlusIcon className="mr-1 w-5 h-5" />
            Add client
          </Button>
        </div>
      </Card.Title>
      <Card.Body>
        <ClientsTable
          data={data.data}
          currentPage={pageIndex}
          onPageChange={setPagination}
          totalRowsCount={data.totalRowsCount}
        />
      </Card.Body>
    </Card>
  )
}

const ClientsTable = (props) => {
  const columns = useMemo(() => [
    {
      header: 'Name',
      accessorKey: 'name',
      cell: ({ row }) => <Table.Link url={`/clients/${row.original.id}`}>{row.original.name}</Table.Link>
    },
    {
      header: 'Email',
      accessorKey: 'email'
    },
    {
      header: 'Phone',
      accessorKey: 'tel'
    },
    /*{
      header: 'Orders',
      id: 'orders',
      accessorFn: () => 'tbd'
    }*/
  ])

  const data = useMemo(() => props.data, [props.data])

  return (
    <Table
      cols={columns}
      data={data}
      isPaginated={true}
      currentPage={props.currentPage}
      onPageChange={props.onPageChange}
      totalRowsCount={props.totalRowsCount}
    />
  )
}

export default ClientsList