import { useRef } from 'react'
import { Card } from 'components/gsys-ui'
import { BiBarcodeReader } from 'react-icons/bi'
import Barcode from 'react-barcode'
import { Footer } from 'components/ui/form/Form'
import { Button } from 'components/gsys-ui'
import { CheckIcon, PrinterIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useContext } from 'react'
import { ModalContext } from 'context/ModalContext'

const BarcodeModal = ({ descTop, descBottom, code }) => {
  const modal = useContext(ModalContext)
  const barcodeRef = useRef(null)

  const handlePrint = () => {
    const newTab = window.open()
    console.log(barcodeRef)
    newTab.document.body.innerHTML = barcodeRef.current.innerHTML
    newTab.print()
    newTab.close()
  }

  return (
    <Card className="m-auto">
      <Card.Title Icon={BiBarcodeReader}>Print Barcode</Card.Title>
      <Card.Body>
        <div className="flex justify-center my-2 w-full">
          <div ref={barcodeRef} className="h-[92px] mb-4">
            <div className="font-mono font-bold text-center text-black">{descTop}</div>
            <Barcode
              ref={barcodeRef}
              height={70}
              width={2}
              margin={0}
              renderer="img"
              value={code}
            />
            <div className="font-mono text-sm font-bold text-center text-black">{descBottom}</div>
          </div>
        </div>
        <Footer>
          <Button variant="white" onClick={() => modal.close()}>
            <XMarkIcon className="mr-1 w-5 h-5" />
            Close
          </Button>
          <Button onClick={handlePrint}>
            <PrinterIcon className="mr-1 w-5 h-5" />
            Print
          </Button>
        </Footer>
      </Card.Body>

    </Card>
  )
}

export default BarcodeModal