import { useContext } from 'react'

import { Form } from 'components/ui/form/Form'
import JobForm from 'components/clients/JobForm'
import { jobValidation } from 'util/validations'
import { useAddJob, useUpdateJob } from 'hooks/mutations'
import { DocumentTextIcon } from '@heroicons/react/24/outline'
import { ModalContext } from 'context/ModalContext'
import { Card } from 'components/gsys-ui'

const EditJobModal = ({ jobId, initial }) => {
  const modal = useContext(ModalContext)
  const mutation = useUpdateJob(jobId)

  return (
    <Card className="m-auto">
      <Card.Title Icon={DocumentTextIcon}>Edit a job</Card.Title>
      <Card.Body>
        <Form
          validation={jobValidation}
          initial={initial}
          mutation={mutation}
          FormComp={JobForm}
          formProps={{ isEdit: true }}
          onSubmit={modal.close}
          isEdit={true}
          context={{ isPatch: true }}
        />
      </Card.Body>
    </Card>
  )
}

export default EditJobModal