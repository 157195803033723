const Avatar = ({ src, size = 50 }) => {
  return (
    <div className="rounded-full border-[3px] border-blue-300 transition-colors cursor-pointer hover:border-blue-500">
      <img
        src={src}
        className="rounded-full border-[3px] border-white"
        alt="avatar"
        style={{
          width: `${size}px`,
          height: `${size}px`
        }}
      />
    </div>
  )
}

export default Avatar