import { useState } from 'react'
import { FieldArray, useFormikContext, ErrorMessage } from 'formik'

import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import { DropdownFormik, Input, Button, InputFormik } from 'components/gsys-ui'
import { useParts } from 'util/queries'

const KitPartsInput = ({ name, label }) => {
  const { isLoading, isError, data: res, error } = useParts()
  console.log(res)
  const { values, errors } = useFormikContext()

  const options = isLoading || isError ? [] : (
    res.data.map((p) => ({
      label: `[${p.code}] ${p.name}`,
      value: p.id
    }))
  )

  return (
    <FieldArray name={name}>
      {({ insert, remove, push }) => (
        <div className="w-full">
          <div className="flex justify-between items-center w-full">
            <div className="text-sm font-semibold">Kit contents</div>
            <Button
              onClick={() => push({ partId: null, qty: 1 })}
              loading={isLoading}
            >
              <PlusIcon className="w-5 h-5" />
            </Button>
          </div>

          <div>
            {
              values[name].length > 0 && values[name].map((link, ind) => (
                <div className="mb-2">
                  <div key={ind} className="flex items-end space-x-2">
                    <div className="flex-1 min-w-0">
                      <Dropdown
                        options={options}
                        ind={ind}
                        name={name}
                        isLoading={isLoading}
                      />
                    </div>
                    <div className="flex-none">
                      <InputFormik
                        noError={true}
                        className="!w-[60px]"
                        label={ind === 0 && 'Qty.'}
                        name={`${name}[${ind}].qty`}
                      />
                    </div>
                    <div className="flex-none">
                      <Button variant="red" onClick={() => remove(ind)}>
                        <TrashIcon className="w-5 h-5" />
                      </Button>
                    </div>
                  </div>
                  <div className="text-sm text-red-600">
                    {errors?.[name]?.[ind]?.partId || errors?.[name]?.[ind]?.qty}
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      )}
    </FieldArray>
  )
}

const Dropdown = ({ options, ind, name, isLoading }) => {
  const [inputValue, setInputValue] = useState('')

  return (
    <DropdownFormik
      noError={true}
      label={ind === 0 && 'Part'}
      name={`${name}.${ind}.partId`}
      options={options}
      isLoading={isLoading}
      loadingMessage="Loading parts..."
      placeholder="Type to search for a part..."
      menuIsOpen={inputValue.length > 0}
      inputValue={inputValue}
      onInputChange={(v) => setInputValue(v)}
    />
  )
}

export default KitPartsInput