export const get = async (url, params, options = {}) => {
  const paramsEnc = new URLSearchParams(params).toString()
  const pString = paramsEnc ? '?' + paramsEnc : ''

  const res = await fetch(url + pString, {
    method: 'GET',
    credentials: 'include'
  })

  return res.json()
}

export const body = async (type, url, data, options = {}, headers = {}) => {
  const res = await fetch(url, {
    method: type,
    body: JSON.stringify(data),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...headers
    },
    ...options
  })

  try {
    const json = await res.json()
    return json
  } catch (e) {
    console.log(e)
    return
  }
}