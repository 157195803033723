import { DropdownFormik } from 'components/gsys-ui'
import { useState } from 'react'
import { useParts } from 'util/queries'

const PartInput = ({ name, label, isDisabled }) => {
  const { isLoading, isError, data: res, error } = useParts()
  const [inputValue, setInputValue] = useState('')

  const options = isLoading || isError ? [] : (
    res.data.map((p) => ({
      label: `[${p.code}] ${p.name}`,
      value: p.id
    }))
  )

  return (
    <div className="w-full">
      <DropdownFormik
        noError={true}
        label={label}
        name={name}
        options={options}
        isLoading={isLoading}
        loadingMessage="Loading parts..."
        placeholder="Type to search for a part..."
        isDisabled={isDisabled}
        menuIsOpen={inputValue.length > 0}
        inputValue={inputValue}
        onInputChange={(v) => setInputValue(v)}
      />
    </div>
  )
}

export default PartInput