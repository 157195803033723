import { useContext, useEffect, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import download from 'downloadjs'
import * as Yup from 'yup'

import { ModalContext } from 'context/ModalContext'
import { ArrowPathIcon, CheckIcon, EyeSlashIcon, ListBulletIcon, WrenchIcon } from '@heroicons/react/24/outline'
import Table from 'components/ui/Table'
import { BaseInput, Button, Card, Checkbox, Input, MultiButton } from 'components/gsys-ui'
import { BiBarcodeReader } from 'react-icons/bi'
import * as c from 'util/constants'
import * as req from 'util/request'
import moment from 'moment'
import { useParts, usePartsLastRefreshTime } from 'util/queries'
import haxios from 'util/axios'
import LocFilter from './LocFilter'
import ConfirmModal from './ConfirmModal'
import clsx from 'clsx'
import { useModifyAlerts } from 'hooks/mutations'

const modOverride = (modified, current, key) => {
  if (modified && Object.hasOwn(modified, key)) return modified[key]
  return current[key]
}

const PartsList = ({ selectedParts, togglePart, selectPart, deselectPart }) => {
  const modal = useContext(ModalContext)
  const mutation = useModifyAlerts()
  const queryClient = useQueryClient()
  const [filter, setFilter] = useState('all')
  const [locFilterValue, setLocFilterValue] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const [{ pageIndex, pageSize }, setPagination] = useState({ pageIndex: 0, pageSize: 10 })
  const filterUrl = filter === 'all' ? '' : filter
  const locQuery = locFilterValue?.value ? encodeURIComponent(locFilterValue.value) : null
  const { isLoading, isError, data: res, error } = useParts(filterUrl, pageIndex, searchValue, locQuery, { notifyOnChangeProps: 'tracked' })
  const [isRefreshLoading, setRefreshLoading] = useState(false)
  const [isSavePartRowsLoading, setSavePartRowsLoading] = useState(false)
  const [modifyPartRows, setModifyPartRows] = useState({})

  const handleRefreshParts = async () => {
    setRefreshLoading(true)
    await req.get(`${c.BASE_URL}/parts/refresh`)
    await queryClient.invalidateQueries({ queryKey: ['parts'] })
    setRefreshLoading(false)
  }

  const handleSavePartRows = async () => {
    setSavePartRowsLoading(true)

    const data = Object.keys(modifyPartRows).map((code) => {
      let row = { code }
      if (Object.hasOwn(modifyPartRows[code], 'stockAlerts')) row.stockAlerts = !!modifyPartRows[code].stockAlerts
      if (Object.hasOwn(modifyPartRows[code], 'alertQty')) row.alertQty = parseInt(modifyPartRows[code].alertQty)
      return row
    })

    await mutation.mutateAsync(data)
    setSavePartRowsLoading(false)
  }

  const handleModifyPartRow = (code, obj) => {
    const newModifyPartRows = {
      ...modifyPartRows,
      [code]: {
        ...modifyPartRows[code],
        ...obj
      }
    }

    setModifyPartRows(newModifyPartRows)
  }

  const resetPagination = () => setPagination({ pageIndex: 0, pageSize: 10 })

  const checkAlertsHaveChanged = () => {
    return Object.keys(modifyPartRows).some((code) => {
      const row = res.data.results.filter((el) => el.code === code).pop()
      if (!row) return false
      const alertHasChanged = Object.hasOwn(modifyPartRows[code], 'stockAlerts') && row.stockAlerts !== modifyPartRows[code].stockAlerts
      const qtyHasChanged = Object.hasOwn(modifyPartRows[code], 'alertQty') && parseInt(row.alertQty) !== parseInt(modifyPartRows[code].alertQty)
      console.log(modifyPartRows[code], row)
      console.log(alertHasChanged || qtyHasChanged)
      return alertHasChanged || qtyHasChanged
    })
  }

  const checkUnsaved = (func) => (...args) => {
    if (checkAlertsHaveChanged()) {
      modal.set(
        <ConfirmModal
          doDelete={() => {
            setModifyPartRows({})
            func(...args)
          }}
        />
      )
    } else {
      func(...args)
    }
  }

  const handleChangeFilter = checkUnsaved((val) => {
    setFilter(val)
    resetPagination()
  })

  const handleSearch = checkUnsaved((val) => {
    setSearchValue(val)
    resetPagination()
  })

  const handleSetLoc = checkUnsaved((val) => {
    setLocFilterValue(val)
    resetPagination()
  })

  const handlePageChange = checkUnsaved((val) => {
    setPagination(val)
  })

  if (isLoading) return null
  if (isError) return <div>{JSON.stringify(error)}</div>
  if (res.error) return <div>{JSON.stringify(res)}</div>

  const stockAlertsHaveChanged = checkAlertsHaveChanged()

  return (
    <Card>
      <Card.Title Icon={WrenchIcon}>
        <div>Parts</div>
        <div className="flex items-center space-x-3">
          <div className="w-48">
            <Input
              value={searchValue}
              onChange={(e) => handleSearch(e.target.value)}
              placeholder="Type to search..."
            />
          </div>
          <div className="w-48">
            <LocFilter value={locFilterValue} onChange={handleSetLoc} />
          </div>
        </div>
      </Card.Title>
      <Card.Body>
        <PartsTable
          data={res.data.results}
          currentPage={pageIndex}
          onPageChange={handlePageChange}
          totalRowsCount={res.totalRowsCount}
          selectedParts={selectedParts}
          togglePart={togglePart}
          selectPart={selectPart}
          deselectPart={deselectPart}
          modifyPartRows={modifyPartRows}
          handleModifyPartRow={handleModifyPartRow}
        />
        <div className="flex justify-between items-end mt-2">
          <div className="flex space-x-2">
            <MultiButton
              value={filter}
              options={[
                { label: 'All parts', value: 'all' },
                { label: 'In stock', value: 'inStock' },
                { label: 'Out of stock', value: 'outOfStock' }
              ]}
              onChange={handleChangeFilter}
            />
          </div>
          <div className="flex items-end space-x-4">
            <RefreshTimeIndicator />
            <Button variant="white" onClick={handleRefreshParts} loading={isRefreshLoading}>
              <ArrowPathIcon className="mr-1 w-5 h-5" />
              Refresh
            </Button>

            {
              stockAlertsHaveChanged && (
                <Button variant="green" onClick={handleSavePartRows} loading={isSavePartRowsLoading}>
                  <CheckIcon className="mr-1 w-5 h-5" />
                  Save stock alerts
                </Button>
              )
            }
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}

const RefreshTimeIndicator = () => {
  const { isLoading, isError, data: res, error } = usePartsLastRefreshTime()

  if (isLoading) return null
  if (isError) return <div>{JSON.stringify(error)}</div>
  if (res.error) return <div>{JSON.stringify(res)}</div>

  return (
    <div className="mt-2 text-sm italic text-right">
      Last refreshed at: {moment(res.data.value).format('DD/MM/YY HH:mm')}
    </div>
  )
}

const PartsTable = (props) => {
  const currentPageIds = props.data.map((p) => p.id)
  const partsSelectedCurrentPage = props.selectedParts.filter((p) => currentPageIds.includes(p.id))
  const allSelected = partsSelectedCurrentPage.length === props.data.length

  const selectAllToggle = () => {
    if (allSelected) {
      props.deselectPart(props.data)
    } else {
      props.selectPart(props.data)
    }
  }

  const downloadBarcodePdf = async (id) => {
    const pdf = await haxios.get(`/parts/${id}/barcode`, { responseType: 'blob' })
    download(pdf, id + '.pdf', 'application/pdf')
  }

  const columns = useMemo(() => [
    {
      id: 'checkbox',
      cellSize: 20,
      cellPad: false,
      headerPad: false,
      header: () => (
        <Checkbox
          checked={allSelected}
          onClick={selectAllToggle}
        />
      ),
      cell: ({ row }) => {
        return (
          <Checkbox
            checked={props.selectedParts.filter((p) => p.id === row.original.id).length > 0}
            onClick={() => props.togglePart(row.original)}
          />
        )
      }
    },
    {
      header: 'Code',
      accessorKey: 'code',
      cellSize: 1,
      noWrap: true
    },
    {
      header: 'Name',
      accessorKey: 'name',
      truncate: true
    },
    {
      header: 'Price',
      accessorKey: 'price',
      cellSize: 1,
      cell: ({ row }) => `£${row.original.price.toFixed(2)}`
    },
    {
      header: 'Stock',
      cellSize: 1,
      accessorKey: 'stock'
    },
    {
      header: 'Alerts?',
      cellSize: 1,
      cellPad: false,
      id: 'alerts',
      cell: ({ row }) => (
        <SetAlertCheckbox
          key={`stockAlerts${row.original.code}`}
          row={row.original}
          modifyPartRows={props.modifyPartRows}
          handleModifyPartRow={props.handleModifyPartRow}
        />
      )
    },
    {
      header: 'Alert level',
      cellSize: 100,
      id: 'alertStock',
      cellPad: false,
      cell: ({ row }) => (
        modOverride(props.modifyPartRows[row.original.code], row.original, 'stockAlerts') && (
          <AlertLevelInput
            key={`alertQty${row.original.code}`}
            row={row.original}
            modifyPartRows={props.modifyPartRows}
            handleModifyPartRow={props.handleModifyPartRow}
          />
        )
      )
    },
    {
      header: 'Loc',
      cellSize: 1,
      accessorKey: 'loc'
    },
    {
      header: '',
      id: 'cscartStatus',
      cellSize: 25,
      cellPad: false,
      cell: ({ row }) => row.original.cscartStatus === 'H' && (
        <EyeSlashIcon className="w-6 h-6 text-gray-700" title="This product is hidden" />
      )
    },
    {
      header: '',
      id: 'barcode',
      cellSize: 20,
      cellPad: false,
      cell: ({ row }) => (
        <Button
          variant="rounded"
          onClick={() => downloadBarcodePdf(row.original.id)}
        >
          <BiBarcodeReader className="w-6 h-6 m-[-4px] text-gray-700" />
        </Button>
      )
    }
  ])

  const data = useMemo(() => props.data, [props.data])

  return (
    <Table
      cols={columns}
      data={data}
      isPaginated={true}
      currentPage={props.currentPage}
      onPageChange={props.onPageChange}
      totalRowsCount={props.totalRowsCount}
    />
  )
}

const SetAlertCheckbox = ({ row, modifyPartRows, handleModifyPartRow }) => {
  const value = modifyPartRows[row.code] && Object.hasOwn(modifyPartRows[row.code], 'stockAlerts') ? modifyPartRows[row.code].stockAlerts : row.stockAlerts
  const isModified = modifyPartRows[row.code]?.stockAlerts && (modifyPartRows[row.code]?.stockAlerts !== row.stockAlerts)

  const handleClick = () => {
    const newStockAlerts = modifyPartRows[row.code] && Object.hasOwn(modifyPartRows[row.code], 'stockAlerts') ? !modifyPartRows[row.code]?.stockAlerts : !row.stockAlerts
    const newAlertQty = newStockAlerts === false ? 0 : modifyPartRows[row.code]?.alertQty || row.alertQty

    handleModifyPartRow(row.code, {
      stockAlerts: newStockAlerts,
      alertQty: newAlertQty
    })
  }

  return (
    <Checkbox
      className={clsx(isModified && '!bg-blue-200')}
      checked={value}
      onClick={handleClick}
    />
  )
}

const AlertLevelInput = ({ row, modifyPartRows, handleModifyPartRow, setPauseRefresh }) => {
  const value = modifyPartRows[row.code]?.alertQty || row.alertQty
  const [localValue, setLocalValue] = useState(value)
  const isModified = modifyPartRows[row.code]?.alertQty && (modifyPartRows[row.code]?.alertQty !== row.alertQty)
  const isError = !(/^[0-9]*$/.test(value))

  const handleChange = (e) => {
    setLocalValue(e.target.value)
  }

  const handleBlur = (e) => {
    handleModifyPartRow(row.code, { alertQty: parseInt(e.target.value) })
  }

  useEffect(() => {
    setLocalValue(value)
  }, [value])

  return (
    <BaseInput
      className={clsx(
        '!h-8',
        isModified && '!border-blue-200 !border-2',
        isError && '!border-red-400 !border-2'
      )}
      value={localValue}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  )
}

export default PartsList