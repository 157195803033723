import { Spinner } from 'components/gsys-ui'

const LoadingPage = () => {
  return (
    <div className="flex justify-center items-center h-full">
      <Spinner />
    </div>
  )
}

export default LoadingPage