import { Dropdown } from 'components/gsys-ui'
import { useLocs } from 'util/queries'

const LocFilter = ({ value, onChange }) => {
  const { isLoading, isError, data: res, error } = useLocs()

  const options = isLoading || isError ? [] : (
    res.data.map((loc) => ({
      label: loc,
      value: loc
    }))
  )

  return (
    <Dropdown
      style={{ width: '90px' }}
      options={options}
      value={value}
      placeholder="Select Loc..."
      isClearable={true}
      onChange={onChange}
    />
  )
}

export default LocFilter