import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { DocumentTextIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'

import Table from 'components/ui/Table'
import { Card, Button, Grid, Col } from 'components/gsys-ui'
import { formatJobId } from 'util/helpers'
import { ModalContext } from 'context/ModalContext'
import { useDeleteJob } from 'hooks/mutations'
import DeleteModal from 'components/ui/modal/DeleteModal'
import JobStatusBadge from 'components/clients/JobStatusBadge'
import EditJobModal from 'components/clients/EditJobModal'
import moment from 'moment'
import { useJob } from 'util/queries'

const JobDetails = ({ jobId }) => {
  const { isLoading, isError, data: res, error } = useJob(jobId)
  const deleteMutation = useDeleteJob(jobId)
  const modal = useContext(ModalContext)
  const navigate = useNavigate()

  if (isLoading) return null
  if (isError) return <div>{JSON.stringify(error)}</div>
  if (res.error) return <div>{JSON.stringify(res)}</div>

  return (
    <Card>
      <Card.Title Icon={DocumentTextIcon}>
        <div>Job details</div>
        <div>
          <Button
            variant="rounded"
            onClick={() => modal.set(
              <EditJobModal
                jobId={jobId}
                initial={{
                  status: res.data.status,
                  regNo: res.data.regNo,
                  mileageIn: res.data.mileageIn,
                  mileageOut: res.data.mileageOut,
                  isKeyFobWritten: res.data.isKeyFobWritten,
                  hasCarCover: res.data.hasCarCover,
                  hasMot: res.data.hasMot,
                  isTaxed: res.data.isTaxed
                }}
              />
            )}
          >
            <PencilSquareIcon className="w-5 h-5" />
          </Button>
          <Button
            variant="rounded"
            onClick={() => modal.set(
              <DeleteModal
                name="job"
                doDelete={deleteMutation.mutateAsync}
                after={() => navigate('/jobs')}
              />
            )}
          >
            <TrashIcon className="w-5 h-5" />
          </Button>
        </div>
      </Card.Title>
      <Card.Body>
        <Grid gap={8}>
          <Col width={4}><div className="font-bold">Client</div></Col>
          <Col width={8}><Table.Link url={`/clients/${res.data.clientId}`}>{res.data.clientName}</Table.Link></Col>
          <Col width={4}><div className="font-bold">Creator</div></Col>
          <Col width={8}>{res.data.userName}</Col>
          <Col width={4}><div className="font-bold">Job no.</div></Col>
          <Col width={8}>{formatJobId(res.data.id)}</Col>
          <Col width={4}><div className="font-bold">Reg no.</div></Col>
          <Col width={8}>{res.data.regNo}</Col>
          <Col width={4}><div className="font-bold">Status</div></Col>
          <Col width={8}><JobStatusBadge status={res.data.status} /></Col>
          <Col width={4}><div className="font-bold">Part count</div></Col>
          <Col width={8}>{res.data.itemCount || 0}</Col>
          <Col width={4}><div className="font-bold">Value</div></Col>
          <Col width={8}>{res.data.totalValue ? `£${res.data.totalValue.toFixed(2)}` : '£0.00'}</Col>
          <Col width={4}><div className="font-bold">Mileage in</div></Col>
          <Col width={8}>{res.data.mileageIn || '-'}</Col>
          <Col width={4}><div className="font-bold">Mileage out</div></Col>
          <Col width={8}>{res.data.mileageOut || '-'}</Col>
          <Col width={4}><div className="font-bold">Start time</div></Col>
          <Col width={8}>{moment(res.data.createdAt).format('DD/MM/YY HH:mm')}</Col>
          {
            res.data.status === 'complete' && (
              <>
                <Col width={4}><div className="font-bold">Finish time</div></Col>
                <Col width={8}>{moment(res.data.completedAt).format('DD/MM/YY HH:mm')}</Col>
              </>
            )
          }
        </Grid>
      </Card.Body>
    </Card>
  )
}

export default JobDetails