
import { useReactTable, getCoreRowModel, flexRender, getPaginationRowModel } from '@tanstack/react-table'
import { ChevronUpDownIcon, ChevronUpIcon, ChevronDownIcon, ArrowUpRightIcon, ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronDoubleRightIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { Button } from 'components/gsys-ui'

const Table = ({
  data,
  cols,
  isPaginated,
  currentPage,
  onPageChange,
  totalRowsCount,
  emptyMessage,
  pageRowCount = 10,
  showPaginationControls = true,
  columnVisibility = {}
}) => {
  const table = useReactTable({
    data,
    columns: cols,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: !!isPaginated,
    pageCount: isPaginated ? Math.ceil(totalRowsCount / pageRowCount) : -1,
    onPaginationChange: !!isPaginated && onPageChange,
    state: {
      pagination: !!isPaginated && { pageIndex: currentPage, pageSize: pageRowCount },
      columnVisibility
    }
  })

  return (
    <div>
      <table className="w-full">
        <thead>
          {
            table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {
                  headerGroup.headers.map(header => (
                    <th
                      key={header.id}
                      className={clsx(
                        'text-left border-b-2 border-b-grey-400',
                        header.column.columnDef.headerPad !== false && 'p-2'
                      )}
                    >
                      {
                        header.isPlaceholder
                          ? null
                          : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )
                      }
                    </th>
                  ))}
              </tr>
            ))}
        </thead>
        <tbody>
          {
            table.getRowModel().rows.length !== 0 && table.getRowModel().rows.map(row => (
              <tr key={row.id} className="hover:bg-slate-50">
                {
                  row.getVisibleCells().map(cell => {
                    const text = flexRender(cell.column.columnDef.cell, cell.getContext())

                    return (
                      <td
                        key={cell.id}
                        className={clsx(
                          'border-t border-t-grey-400 relative',
                          cell.column.columnDef.cellPad !== false && 'p-2',
                          cell.column.columnDef.noWrap && 'whitespace-nowrap'
                        )}
                        style={{
                          width: cell.column.columnDef.cellSize ? `${cell.column.columnDef.cellSize}px` : undefined,
                        }}
                      >
                        {
                          cell.column.columnDef.truncate ? (
                            <span className="absolute inset-0 p-2 leading-7 truncate" title={cell.getValue()}>{text}</span>
                          ) : text
                        }
                      </td>
                    )
                  })}
              </tr>
            ))}
        </tbody>
      </table>
      {
        table.getRowModel().rows.length === 0 && (
          <div className="flex justify-center items-center p-2 w-full italic">
            {emptyMessage || 'No items to display.'}
          </div>
        )
      }
      {
        (isPaginated && Math.ceil(totalRowsCount / pageRowCount) > 1) ? (
          <div className="flex justify-between items-center mt-2 w-full">
            <div className="space-x-2">
              <Button
                style={{ padding: '0px 10px' }}
                variant="white"
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
              >
                <ChevronDoubleLeftIcon className="w-4 h-4" />
              </Button>
              <Button
                style={{ padding: '0px 10px' }}
                variant="white"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                <ChevronLeftIcon className="w-4 h-4" />
              </Button>
            </div>
            <div>Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}</div>
            <div className="space-x-2">
              <Button
                style={{ padding: '0px 10px' }}
                variant="white"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                <ChevronRightIcon className="w-4 h-4" />
              </Button>
              <Button
                style={{ padding: '0px 10px' }}
                variant="white"
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
              >
                <ChevronDoubleRightIcon className="w-4 h-4" />
              </Button>
            </div>
          </div>
        ) : null
      }
    </div>
  )
}

const SortIcon = ({ isSorted, desc }) => {
  if (isSorted === false) return <ChevronUpDownIcon className="mr-2 w-4 h-4" />
  if (desc === true) return <ChevronDownIcon className="mr-2 w-4 h-4" />
  return <ChevronUpIcon className="mr-2 w-4 h-4" />
}

const TableLink = ({ children, url }) => (
  <Link to={url}>
    <div className="inline-flex items-center text-blue-500 underline">
      <div>{children}</div>
      <ArrowUpRightIcon className="ml-1 w-3 h-3" />
    </div>
  </Link>
)

Table.Link = TableLink

export default Table