import s from './Grid.module.scss'

export const Grid = ({ children, gap = 12, cols = 12 }) => {
  return (
    <div
      className={s.grid}
      style={{
        gridTemplateColumns: `repeat(${cols}, 1fr)`,
        gap: `${gap}px`
      }}
    >
      {children}
    </div>
  )
}

export const Col = ({ children, width = 1, offset }) => {
  return (
    <div
      style={{
        gridColumn: `span ${width} / span ${width}`,
        gridColumnStart: offset ?? 'auto'
      }}
    >
      {children}
    </div>
  )
}