
import { useContext, useMemo, useState } from 'react'
import { ArrowDownTrayIcon, CheckBadgeIcon, PencilIcon, PlusIcon, TrashIcon, WrenchIcon } from '@heroicons/react/24/outline'

import Table from 'components/ui/Table'
import { Card, Button } from 'components/gsys-ui'
import { ModalContext } from 'context/ModalContext'
import { useDeleteJobItem, useUpdateJob } from 'hooks/mutations'
import DeleteModal from 'components/ui/modal/DeleteModal'
import AddPartModal from 'components/job/AddPartModal'
import EditPartModal from 'components/job/EditPartModal'
import JobStatusBadge from 'components/clients/JobStatusBadge'
import * as c from 'util/constants'
import { toMysqlTime } from 'util/helpers'
import { useJob, useJobItems } from 'util/queries'

const PartsList = ({ jobId }) => {
  const modal = useContext(ModalContext)
  const [{ pageIndex, pageSize }, setPagination] = useState({ pageIndex: 0, pageSize: 10 })
  const { isLoading: jobIsLoading, isError: jobIsError, data: jobRes, error: jobError } = useJob(jobId)
  const { isLoading, isError, data: res, error } = useJobItems(jobId, pageIndex, { refetchInterval: 2000 })
  const updateJob = useUpdateJob(jobId)
  const [completeLoading, setCompleteLoading] = useState(false)

  const handleCompleteJob = async () => {
    setCompleteLoading(true)
    await updateJob.mutateAsync({ status: 'complete', completedAt: toMysqlTime(new Date()) })
    setCompleteLoading(false)
  }

  if (isLoading || jobIsLoading) return null
  if (isError || jobIsError) return <div>{JSON.stringify(error)}</div>
  if (res.error || jobRes.error) return <div>{JSON.stringify(res)}</div>

  return (
    <Card>
      <Card.Title Icon={WrenchIcon}>
        <div>Parts list</div>
        <div className="space-x-2">
          {
            jobRes.data.status === 'inProgress' && (
              <Button onClick={() => modal.set(<AddPartModal initial={{ headId: jobId }} />)}>
                <PlusIcon className="mr-1 w-5 h-5" />
                Add part
              </Button>
            )
          }
          <a href={`${c.BASE_URL}/jobs/${jobId}/items/export`}>
            <Button>
              <ArrowDownTrayIcon className="mr-1 w-5 h-5" />
              Export parts
            </Button>
          </a>
          {
            jobRes.data.status === 'inProgress' && (
              <Button variant="green" onClick={handleCompleteJob} loading={completeLoading}>
                <CheckBadgeIcon className="mr-1 w-5 h-5" />
                Complete
              </Button>
            )
          }
        </div>
      </Card.Title>
      <Card.Body>
        <PartsTable
          data={res.data}
          status={jobRes.data.status}
          jobId={jobId}
          currentPage={pageIndex}
          onPageChange={setPagination}
          totalRowsCount={res.totalRowsCount}
        />
      </Card.Body>
    </Card>
  )
}

const PartsTable = (props) => {
  const modal = useContext(ModalContext)

  const columns = useMemo(() => [
    {
      header: 'Code',
      accessorKey: 'partCode',
      cellSize: 1,
      noWrap: true
    },
    {
      header: 'Name',
      accessorKey: 'partName',
      truncate: true
    },
    {
      header: 'Qty.',
      accessorKey: 'qty',
      cellSize: 1
    },
    {
      header: 'Price',
      accessorKey: 'price',
      cell: ({ row }) => `£${row.original.price.toFixed(2)}`,
      cellSize: 1
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cell: ({ row }) => <JobStatusBadge status={row.original.status} />,
      cellSize: 1
    },
    {
      header: '',
      accessorKey: 'edit',
      cellSize: 20,
      disableSortBy: true,
      cellPad: false,
      cell: ({ row }) => (
        <Button
          variant="rounded"
          onClick={() => modal.set(
            <EditPartModal initial={{ headId: props.jobId, id: row.original.id, partId: row.original.partId, qty: row.original.qty }} />
          )}
        >
          <PencilIcon className="w-5 h-5" />
        </Button>
      )
    },
    {
      header: '',
      accessorKey: 'delete',
      cellSize: 20,
      disableSortBy: true,
      cellPad: false,
      cell: ({ row }) => <PartDeleteButton jobId={props.jobId} partId={row.original.partId} />
    }
  ])

  console.log(props.data)
  const data = useMemo(() => props.data, [props.data])

  return (
    <Table
      cols={columns}
      data={data}
      columnVisibility={{
        edit: props.status === 'inProgress' ? true : false,
        delete: props.status === 'inProgress' ? true : false
      }}
      isPaginated={true}
      currentPage={props.currentPage}
      onPageChange={props.onPageChange}
      totalRowsCount={props.totalRowsCount}
    />
  )
}

const PartDeleteButton = ({ jobId, partId }) => {
  const modal = useContext(ModalContext)
  const deleteMutation = useDeleteJobItem(jobId, partId)

  return (
    <Button
      variant="rounded"
      onClick={() => modal.set(
        <DeleteModal
          name="part"
          doDelete={deleteMutation.mutateAsync}
        />
      )}
    >
      <TrashIcon className="w-5 h-5" />
    </Button>
  )
}

export default PartsList