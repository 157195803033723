import { Badge } from 'components/gsys-ui'

const StatusBadge = ({ status }) => {
  if (status === 'inProgress') return <Badge variant="blue" style={{ width: '85px' }}>In progress</Badge>
  if (status === 'complete') return <Badge variant="green" style={{ width: '85px' }}>Complete</Badge>
  if (status === 'saved') return <Badge variant="green" style={{ width: '85px' }}>Saved</Badge>
  if (status === 'saving') return <Badge variant="yellow" style={{ width: '85px' }}>Saving</Badge>
  if (status === 'error') return <Badge variant="red" style={{ width: '85px' }}>Error</Badge>
  return <Badge variant="blue">Unknown</Badge>
}

export default StatusBadge