import { useState, useLayoutEffect } from 'react'
import { Router, Routes, Route, Outlet, useLocation, Navigate } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import LoginPage from 'components/pages/LoginPage'
import LoadingPage from 'components/pages/LoadingPage'
import Layout from 'components/ui/Layout'
import useAuth from 'hooks/useAuth'
import ClientsPage from 'components/pages/ClientsPage'
import { ModalContextProvider } from 'context/ModalContext'
import ActiveJobsPage from 'components/pages/ActiveJobsPage'
import JobPage from 'components/pages/JobPage'
import ModalWrapper from 'components/ui/modal/ModalWrapper'
import SettingsPage from 'components/pages/SettingsPage'
import PartsPage from 'components/pages/PartsPage'
import customHistory from 'util/history'
import axios from 'util/axios'
import PartKitsPage from 'components/pages/PartKitsPage'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: 5000,
      keepPreviousData: true
    }
  }
})

// https://stackoverflow.com/a/70012117
const CustomRouter = ({ history, ...props }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location
  })

  useLayoutEffect(() => history.listen(setState), [history])

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  )
}

const App = () => {
  return (
    <ModalContextProvider>
      <QueryClientProvider client={queryClient}>
        <CustomRouter history={customHistory}>
          <Routes>
            <Route element={<RequireAuth />}>
              <Route element={<Layout />}>
                <Route path="/" element={<ActiveJobsPage />} />
                <Route path="/clients">
                  <Route path=":clientId" element={<ClientsPage />} />
                  <Route path="" element={<ClientsPage />} />
                </Route>
                <Route path="/jobs">
                  <Route path=":jobId">
                    <Route path="partslists/:headId" element={<JobPage />} />
                    <Route path="" element={<JobPage />} />
                  </Route>
                  <Route path="" element={<ActiveJobsPage />} />
                </Route>
                <Route path="/settings" element={<SettingsPage />} />
                <Route path="/parts" element={<PartsPage />} />
                <Route path="/kits" element={<PartKitsPage />} />
              </Route>
            </Route>
            <Route path="/login" element={<LoginPage />} />
            <Route path="*" element={<div>404</div>} />
          </Routes>
          <ModalWrapper />
        </CustomRouter>
      </QueryClientProvider>
    </ModalContextProvider>
  )
}

const RequireAuth = () => {
  const { isAuthed, isLoading } = useAuth()
  const location = useLocation()

  if (isLoading) {
    return <LoadingPage />
  }

  if (isAuthed) {
    return <Outlet />
  }

  return <Navigate to="/login" state={{ from: location }} />
}

export default App