import { useContext, useEffect, useMemo, useState } from 'react'
import { CheckIcon, EnvelopeIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'

import { ModalContext } from 'context/ModalContext'
import Table from 'components/ui/Table'
import CreateTokenModal from 'components/settings/CreateTokenModal'
import { Card, Button, BaseInput } from 'components/gsys-ui'
import DeleteModal from 'components/ui/modal/DeleteModal'
import { useDeleteToken, useUpdateSetting } from 'hooks/mutations'
import BarcodeModal from 'components/parts/BarcodeModal'
import { BiBarcodeReader } from 'react-icons/bi'
import { useSettings } from 'util/queries'

const StockAlertsSettings = () => {
  const { isLoading, isError, data: res, error } = useSettings()
  const mutation = useUpdateSetting('stockAlertsEmail')
  const email = (res?.data || []).filter((el) => el.key === 'stockAlertsEmail').pop()
  const [localValue, setLocalValue] = useState('')
  const isModified = email && localValue !== email?.value
  const [isSaving, setSaving] = useState(false)

  useEffect(() => {
    setLocalValue(email?.value || '')
  }, [email])

  const handleClick = async () => {
    setSaving(true)
    await mutation.mutateAsync({ value: localValue })
    setSaving(false)
  }

  if (isLoading) return null
  if (isError) return <div>{JSON.stringify(error)}</div>
  if (res.error) return <div>{JSON.stringify(res)}</div>

  return (
    <Card>
      <Card.Title Icon={EnvelopeIcon}>
        <div>Stock Alerts Email Address</div>
      </Card.Title>
      <Card.Body>
        <div className="flex items-center space-x-2">
          <BaseInput value={localValue} onChange={(e) => setLocalValue(e.target.value)} />
          <Button variant="green" disabled={!isModified || isSaving} onClick={handleClick} loading={isSaving}><CheckIcon className="mr-1 w-5 h-5" />Save</Button>
        </div>
        {
          isModified && (
            <div className="text-red-500">You have unsaved changes.</div>
          )
        }
      </Card.Body>
    </Card>
  )
}

export default StockAlertsSettings