import { useEffect, useState } from 'react'
import clsx from 'clsx'
import Select from 'react-select'
import { useField, ErrorMessage } from 'formik'

import s from './Dropdown.module.scss'

export const Dropdown = ({ label, error, name, noError, ...props }) => {
  return (
    <div className={s.inputGroup}>
      {
        label && (
          <div className={s.label}>{label}</div>
        )
      }
      <Select
        {...props}
        isSearchable={true}
        classNames={{
          control: (state) => clsx(
            s.dropdownControl,
            error && s.error
          )
        }}
      />
      {
        !noError && (
          name ? (
            <div className={s.error}><ErrorMessage name={name} /></div>
          ) : (
            <div className={s.error}>{error}</div>
          )
        )
      }
    </div>
  )
}

export const DropdownFormik = ({ options, label, ...props }) => {
  const [field, meta, helpers] = useField(props)
  const opt = options.filter((o) => o.value === meta.value).pop()
  const [hasValueChanged, setValueChanged] = useState(false)
  const [value, setValue] = useState(opt)

  console.log('DROPDOWN', options, value)
  useEffect(() => {
    if (hasValueChanged) return
    setValue(options.filter((o) => o.value === meta.value).pop())
  }, [options])

  return (
    <Dropdown
      {...props}
      label={label}
      options={options}
      value={value}
      error={meta.error}
      onChange={(v) => {
        helpers.setValue(v.value)
        setValue(v)
        setValueChanged(true)
      }}
    />
  )
}

export default Dropdown