import { useContext } from 'react'

import { Form } from 'components/ui/form/Form'
import ClientForm from 'components/clients/ClientForm'
import { clientValidation } from 'util/validations'
import { useAddClient } from 'hooks/mutations'
import { UserPlusIcon } from '@heroicons/react/24/outline'
import { ModalContext } from 'context/ModalContext'
import { useNavigate } from 'react-router-dom'
import { Card } from 'components/gsys-ui'

const AddClientModal = ({ initial }) => {
  const modal = useContext(ModalContext)
  const mutation = useAddClient()
  const navigate = useNavigate()

  const handleSubmit = (res) => {
    modal.close()
    navigate(`/clients/${res.data.id}`)
  }

  return (
    <Card className="m-auto">
      <Card.Title Icon={UserPlusIcon}>Add a client</Card.Title>
      <Card.Body>
        <Form
          validation={clientValidation}
          initial={initial}
          mutation={mutation}
          FormComp={ClientForm}
          onSubmit={handleSubmit}
        />
      </Card.Body>
    </Card>
  )
}

export default AddClientModal