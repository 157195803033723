import { useContext, useMemo, useState } from 'react'
import download from 'downloadjs'

import { ModalContext } from 'context/ModalContext'
import { ArchiveBoxIcon, PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import Table from 'components/ui/Table'
import AddKitModal from 'components/parts/AddKitModal'
import { Button, Card } from 'components/gsys-ui'
import { useDeleteKit } from 'hooks/mutations'
import DeleteModal from 'components/ui/modal/DeleteModal'
import { BiBarcodeReader } from 'react-icons/bi'
import EditKitModal from 'components/parts/EditKitModal'
import { useKits } from 'util/queries'
import haxios from 'util/axios'

const KitsList = () => {
  const modal = useContext(ModalContext)
  const { isLoading, isError, data: res, error } = useKits()

  if (isLoading) return null
  if (isError) return <div>{JSON.stringify(error)}</div>
  if (res.error) return <div>{JSON.stringify(res)}</div>

  return (
    <Card>
      <Card.Title Icon={ArchiveBoxIcon}>
        <div>Part kits</div>
        <Button onClick={() => modal.set(<AddKitModal />)}>
          <PlusIcon className="mr-1 w-5 h-5" />
          Add kit
        </Button>
      </Card.Title>
      <Card.Body>
        <KitsTable data={res.data} />
      </Card.Body>
    </Card>
  )
}

const KitsTable = (props) => {
  const [isLoadingBarcode, setLoadingBarcode] = useState(false)
  const modal = useContext(ModalContext)

  const downloadBarcodePdf = async (code) => {
    setLoadingBarcode(true)
    const pdf = await haxios.get(`/kits/${code}/barcode`, { responseType: 'blob' })
    download(pdf, 'barcodes.pdf', 'application/pdf')
    setLoadingBarcode(false)
  }

  const columns = useMemo(() => [
    {
      header: 'Code',
      accessorKey: 'code'
    },
    {
      header: 'Name',
      accessorKey: 'name'
    },
    {
      header: 'Parts',
      accessorKey: 'count',
      cell: ({ row }) => row.original.items.reduce((acc, cur) => acc + cur.qty, 0)
    },
    {
      header: '',
      id: 'barcode',
      cellSize: 20,
      cellPad: false,
      cell: ({ row }) => (
        <Button
          variant="rounded"
          loading={isLoadingBarcode}
          onClick={() => downloadBarcodePdf(row.original.code)}
        >
          <BiBarcodeReader className="w-6 h-6 m-[-4px] text-gray-700" />
        </Button>
      )
    },
    {
      header: '',
      id: 'edit',
      accessorKey: 'edit',
      disableSortBy: true,
      cellPad: false,
      cellSize: 20,
      cell: ({ row }) => (
        <Button
          variant="rounded"
          onClick={() => modal.set(
            <EditKitModal
              kitId={row.original.id}
              initial={{
                name: row.original.name,
                code: row.original.code,
                items: row.original.items.map((i) => ({
                  partId: i.partId,
                  qty: i.qty
                }))
              }}
            />
          )}
        >
          <PencilIcon className="w-5 h-5" />
        </Button>
      )
    },
    {
      header: '',
      id: 'delete',
      accessorKey: 'delete',
      disableSortBy: true,
      cellPad: false,
      cellSize: 20,
      cell: ({ row }) => <KitDeleteButton kitId={row.original.id} />
    }
  ])

  const data = useMemo(() => props.data, [props.data])

  return (
    <Table cols={columns} data={data} />
  )
}

const KitDeleteButton = ({ kitId }) => {
  const modal = useContext(ModalContext)
  const deleteMutation = useDeleteKit(kitId)

  return (
    <Button
      variant="rounded"
      onClick={() => modal.set(
        <DeleteModal
          name="kit"
          doDelete={deleteMutation.mutateAsync}
        />
      )}
    >
      <TrashIcon className="w-5 h-5" />
    </Button>
  )
}

export default KitsList