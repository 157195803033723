import { useContext, useState } from 'react'
import { ExclamationCircleIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline'

import { ModalContext } from 'context/ModalContext'
import { Card, Button } from 'components/gsys-ui'

const DeleteModal = ({ doDelete, name, after = () => { } }) => {
  const modal = useContext(ModalContext)
  const [isDeleting, setDeleting] = useState(false)

  const handleDelete = async () => {
    setDeleting(true)
    await doDelete()
    modal.close()
    after()
  }

  return (
    <Card className="!w-[350px] m-auto">
      <div className="flex flex-col items-center p-8 w-full">
        <div className="flex justify-center items-center mb-2 w-12 h-12 bg-red-100 rounded-full">
          <ExclamationCircleIcon className="w-8 h-8 text-red-400" />
        </div>
        <div className="text-lg font-bold">Delete {name}?</div>
        <div className="mb-4">This can't be undone!</div>
        <div className="flex space-x-4 w-full">
          <Button variant="white" className="flex-1 justify-center" onClick={() => modal.close()}>
            <XMarkIcon className="mr-1 w-5 h-5" />
            Cancel
          </Button>
          <Button variant="red" loading={isDeleting} className="flex-1 justify-center" onClick={handleDelete}>
            <TrashIcon className="mr-1 w-5 h-5" />
            Delete
          </Button>
        </div>
      </div>
    </Card>
  )
}

export default DeleteModal