import { useContext } from 'react'

import { Form } from 'components/ui/form/Form'
import { userValidation } from 'util/validations'
import { useCreateUser, useUpdateUser } from 'hooks/mutations'
import { UserIcon, UserPlusIcon } from '@heroicons/react/24/outline'
import { ModalContext } from 'context/ModalContext'
import { Card } from 'components/gsys-ui'
import UserForm from './UserForm'

const EditUserModal = ({ initial, userId }) => {
  console.log('!!!!!', userId)
  const modal = useContext(ModalContext)
  const mutation = useUpdateUser(userId)

  return (
    <Card className="m-auto">
      <Card.Title Icon={UserIcon}>Edit a user</Card.Title>
      <Card.Body>
        <Form
          validation={userValidation}
          initial={initial}
          mutation={mutation}
          FormComp={UserForm}
          onSubmit={modal.close}
        />
      </Card.Body>
    </Card>
  )
}

export default EditUserModal