import { Cog8ToothIcon } from '@heroicons/react/24/outline'
import { Grid, Col } from 'components/gsys-ui'
import StockAlertsSettings from 'components/settings/StockAlertsSettings'
import TokensList from 'components/settings/TokensList'
import UsersList from 'components/settings/UsersList'
import { Card } from 'react-bootstrap'
import { useOutletContext } from 'react-router-dom'
import { VERSION_STRING } from 'util/constants'

const SettingsPage = () => {

  const { setTitle, setTitleIcon } = useOutletContext()
  setTitle('Settings')
  setTitleIcon(Cog8ToothIcon)
  return (
    <>
      <Grid>
        <Col width={6}>
          <UsersList />
        </Col>
        <Col width={6}>
          <TokensList />
        </Col>
        <Col width={6}>
          <StockAlertsSettings />
        </Col>
      </Grid>
      <div className="w-full text-right text-gray-400">
        EJ Ward Control Panel {VERSION_STRING} by Goldfinch Systems Ltd.
      </div>
    </>
  )
}

export default SettingsPage