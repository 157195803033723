import { useContext, useMemo } from 'react'
import { PencilIcon, PlusIcon, TrashIcon, UsersIcon } from '@heroicons/react/24/outline'

import { ModalContext } from 'context/ModalContext'
import Table from 'components/ui/Table'
import { Card, Button } from 'components/gsys-ui'
import EditUserModal from './EditUserModal'
import { useDeleteUser } from 'hooks/mutations'
import DeleteModal from 'components/ui/modal/DeleteModal'
import AddUserModal from './AddUserModal'
import { useUsers } from 'util/queries'


const UsersList = () => {
  const { isLoading, isError, data: res, error } = useUsers()
  const modal = useContext(ModalContext)

  if (isLoading) return null
  if (isError) return <div>{JSON.stringify(error)}</div>
  if (res.error) return <div>{JSON.stringify(res)}</div>

  return (
    <Card>
      <Card.Title Icon={UsersIcon}>
        <div>Users</div>
        <div>
          <Button onClick={() => modal.set(<AddUserModal />)}>
            <PlusIcon className="mr-1 w-5 h-5" />
            Add user
          </Button>
        </div>
      </Card.Title>
      <Card.Body>
        <UsersTable data={res.data} />
      </Card.Body>
    </Card>
  )
}

const UsersTable = (props) => {
  const modal = useContext(ModalContext)
  const columns = useMemo(() => [
    {
      header: 'Name',
      accessorKey: 'name'
    },
    {
      header: 'Email',
      accessorKey: 'email'
    },
    {
      header: '',
      accessorKey: 'edit',
      cellSize: 20,
      disableSortBy: true,
      cellPad: false,
      cell: ({ row }) => (
        <Button
          variant="rounded"
          onClick={() => modal.set(
            <EditUserModal initial={{ name: row.original.name, email: row.original.email }} userId={row.original.id} />
          )}
        >
          <PencilIcon className="w-5 h-5" />
        </Button>
      )
    },
    {
      header: '',
      accessorKey: 'delete',
      cellSize: 20,
      disableSortBy: true,
      cellPad: false,
      cell: ({ row }) => <UserDeleteButton userId={row.original.id} />
    }
  ])

  const data = useMemo(() => props.data, [props.data])

  return (
    <Table cols={columns} data={data} />
  )
}

const UserDeleteButton = ({ userId }) => {
  const modal = useContext(ModalContext)
  const deleteMutation = useDeleteUser(userId)

  return (
    <Button
      variant="rounded"
      onClick={() => modal.set(
        <DeleteModal
          name="user"
          doDelete={deleteMutation.mutateAsync}
        />
      )}
    >
      <TrashIcon className="w-5 h-5" />
    </Button>
  )
}

export default UsersList