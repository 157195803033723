import { useContext } from 'react'

import { Error, Row, Spacer, Footer } from 'components/ui/form/Form'
import { ModalContext } from 'context/ModalContext'
import { Button, InputFormik } from 'components/gsys-ui'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'

const TokenForm = ({ isSubmitting, handleSubmit, error }) => {
  const modal = useContext(ModalContext)

  return (
    <>
      <Error>{error}</Error>
      <Row>
        <InputFormik name="name" label="Token name" />
      </Row>
      <Spacer />
      <Footer>
        <Button variant="white" onClick={modal.close}>
          <XMarkIcon className="mr-1 w-5 h-5" />
          Cancel
        </Button>
        <Button variant="green" onClick={handleSubmit} loading={isSubmitting}>
          <CheckIcon className="mr-1 w-5 h-5" />
          Submit
        </Button>
      </Footer>
    </>
  )
}

export default TokenForm