import { useNavigate } from 'react-router-dom'

import { Form, Row, Error, Spacer } from 'components/ui/form/Form'
import { loginValidation } from 'util/validations'
import { useLogin } from 'hooks/mutations'
import { Card, Button, InputFormik } from 'components/gsys-ui'

const LoginPage = () => {
  const mutation = useLogin()
  const navigate = useNavigate()

  return (
    <div className="flex justify-center items-center h-full">
      <Card className="px-10 py-8" style={{ width: '380px' }}>
        <div className="flex flex-col items-center">
          <img className="mb-2" src="/logofull.png" />
          <div className="text-xl font-bold">Welcome</div>
          <div className="mb-8">Please log in to continue.</div>
          <Form
            validation={loginValidation}
            mutation={mutation}
            FormComp={LoginForm}
            redirect="/"
            onSubmit={() => navigate('/')}
          />
        </div>
      </Card>
    </div>
  )
}

const LoginForm = ({ isSubmitting, handleSubmit, error }) => {
  return (
    <div className="w-full">
      <Error>{error}</Error>
      <Row>
        <InputFormik name="email" label="Email" />
      </Row>
      <Row>
        <InputFormik
          type="password"
          name="password"
          label="Password"
          onKeyUp={(e) => e.keyCode === 13 && handleSubmit()}
        />
      </Row>
      <Spacer />
      <Button
        onClick={handleSubmit}
        loading={isSubmitting}
        className="py-3 w-full"
      >
        Log in
      </Button>
    </div>
  )
}

export default LoginPage