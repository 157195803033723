import { useQuery } from 'react-query'

import axios from './axios'

export const useClients = (query, filterLetter, page, pageSize = 10, opt = {}) => useQuery({
  queryKey: ['clients', query, filterLetter, page],
  queryFn: () => axios.get(
    '/clients',
    {
      params: {
        ...(query && { query }),
        ...(filterLetter && { filterLetter }),
        page,
        pageSize
      }
    }
  ),
  opt
})

export const useClient = (clientId, opt = {}) => useQuery({
  queryKey: ['clients', clientId],
  queryFn: () => axios.get(`/clients/${clientId}`),
  opt
})

export const useClientJobs = (clientId, page, opt = {}) => useQuery({
  queryKey: ['clients', clientId, 'jobs', page],
  queryFn: () => axios.get(
    `/clients/${clientId}/jobs`,
    { params: { page } }
  ),
  opt
})

export const useJobs = (filter, page, opt = {}) => useQuery({
  queryKey: ['jobs', filter, page],
  queryFn: () => axios.get(
    `/jobs${filter ? `/${filter}` : ''}`,
    { params: { page } }
  ),
  opt
})

export const useJob = (jobId, opt = {}) => useQuery({
  queryKey: ['jobs', jobId],
  queryFn: () => axios.get(`/jobs/${jobId}`),
  opt
})

export const useJobItems = (jobId, page, opt = {}) => useQuery({
  queryKey: ['jobs', jobId, 'items', page],
  queryFn: () => axios.get(
    `/jobs/${jobId}/items`,
    { params: { page } }
  ),
  opt
})

export const useParts = (filter, page, searchValue, locFilter, opt = {}) => {
  return useQuery({
    queryKey: ['parts', filter, page, searchValue, locFilter],
    queryFn: () => axios.get(
      `/parts${filter ? `/${filter}` : ''}`,
      { params: { page, search: searchValue || undefined, loc: locFilter || undefined } }
    ),
    ...opt
  })
}

export const usePartsLastRefreshTime = (opt = {}) => useQuery({
  queryKey: ['parts', 'lastRefreshTime'],
  queryFn: () => axios.get('/parts/lastRefreshTime'),
  opt
})

export const useLocs = (opt = {}) => useQuery({
  queryKey: ['locs'],
  queryFn: () => axios.get('/parts/locs'),
  opt
})

export const useKits = (opt = {}) => useQuery({
  queryKey: ['kits'],
  queryFn: () => axios.get('/kits'),
  opt
})

export const useTokens = (opt = {}) => useQuery({
  queryKey: ['tokens'],
  queryFn: () => axios.get('/tokens'),
  opt
})

export const useUsers = (opt = {}) => useQuery({
  queryKey: ['users'],
  queryFn: () => axios.get('/users'),
  opt
})

export const useSettings = (opt = {}) => useQuery({
  queryKey: ['settings'],
  queryFn: () => axios.get('/settings'),
  opt
})