import { useState } from 'react'
import { Formik } from 'formik'
import set from 'lodash/set'

export const Form = ({ validation, initial = {}, mutation, FormComp, onSubmit: submitCallback, context, formProps = {} }) => {
  const [error, setError] = useState(null)

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    let res

    const valsPatch = (context && context.isPatch) ? Object.keys(values).reduce((acc, cur) => {
      if (values[cur] !== initial[cur]) return { ...acc, [cur]: values[cur] }
      return acc
    }, {}) : values

    try {
      res = await mutation.mutateAsync(values)
    } catch (e) {
      console.log(e)

      if (e.response?.data?.error?.message) {
        setError(e.response.data.error.message)
      } else {
        setError('Unknown error')
      }

      setSubmitting(false)
      return
    }

    setError(res.error?.message || null)
    setSubmitting(false)
    if (!res.error && submitCallback) submitCallback(res)
  }

  const initialValues = {
    ...validation.cast({}),
    ...initial
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnChange={false}
      validateOnBlur={false}
      validate={async (values) => {
        try {
          await validation.validate(values, {
            abortEarly: false,
            context
          })
        } catch (e) {
          if (e.name !== 'ValidationError') throw e

          return e.inner.reduce((errors, currentError) => {
            errors = set(errors, currentError.path, currentError.message)
            return errors
          }, {})
        }

        return {}
      }}
    >
      {
        (formik) => {
          return (
            <FormComp
              {...formProps}
              error={error}
              handleSubmit={formik.handleSubmit}
              isSubmitting={formik.isSubmitting}
            />
          )
        }
      }
    </Formik>
  )
}

export const Error = ({ children }) => (
  <div className="p-2 text-center text-red-400 rounded text-md">
    {children}
  </div>
)

export const Title = ({ children, Icon }) => (
  <div className="flex items-center text-lg font-semibold">
    {Icon && <Icon className="mr-2 w-9 h-9" />}
    <span>{children}</span>
  </div>
)

export const Header = ({ children, Icon }) => (
  <div className="flex items-center font-semibold text-md">
    {Icon && <Icon className="mr-2 w-7 h-7" />}
    <span>{children}</span>
  </div>
)

export const Row = ({ children }) => (
  <div className="flex mb-2 space-x-2">
    {children}
  </div>
)

export const Spacer = () => (
  <div className="h-6" />
)

export const Footer = ({ children }) => (
  <div className="flex justify-end items-center mt-4 space-x-2 w-full">
    {children}
  </div>
)

export const VSpacer = () => (
  <div className="w-full"></div>
)