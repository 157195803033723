import { useContext, useRef } from 'react'
import { createPortal } from 'react-dom'

import { ModalContext } from 'context/ModalContext'

const ModalWrapper = () => {
  const containerRef = useRef(null)
  const modal = useContext(ModalContext)

  const handleClick = (e) => {
    if (e.target === containerRef.current) {
      e.stopPropagation()
      modal.close()
    }
  }

  if (modal.isOpen === false) return null

  if (!modal.content.type) {
    console.log('no modal content')
    return null
  }

  return (
    createPortal(
      (
        <div
          ref={containerRef}
          className="flex fixed inset-0 z-50 justify-center items-center bg-gray-500 bg-opacity-75"
          onClick={handleClick}
        >
          <div className="w-[700px] h-full py-8 overflow-y-scroll scrollbar-hide flex">
            {modal.content}
          </div>
        </div>
      ),
      document.getElementById('modal-root')
    )
  )
}

export default ModalWrapper