import { useOutletContext, useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { ArrowUturnLeftIcon, UsersIcon } from '@heroicons/react/24/outline'

import ClientSearchField from 'components/clients/ClientSearchField'
import ClientDetails from 'components/clients/ClientDetails'
import ClientJobs from 'components/clients/ClientJobs'
import ClientsList from 'components/clients/ClientsList'
import { Button, Grid, Col } from 'components/gsys-ui'

const ClientsPage = () => {
  const params = useParams()
  const { setTitle, setTitleIcon } = useOutletContext()
  setTitle('Clients')
  setTitleIcon(UsersIcon)

  return (
    <Grid>
      <Col width={8}>
        <ClientSearchField />
      </Col>
      <Col width={4}>
        <div className="flex justify-end items-center h-full">
          {
            params.clientId && (
              <Link to="/clients">
                <Button>
                  <ArrowUturnLeftIcon className="mr-1 w-5 h-5" />
                  All clients
                </Button>
              </Link>
            )
          }

        </div>
      </Col>
      {
        params.clientId ? (
          <>
            <Col width={4}>
              <ClientDetails clientId={params.clientId} />
            </Col>
            <Col width={8}>
              <ClientJobs clientId={params.clientId} />
            </Col>
          </>
        ) : (
          <Col width={12}>
            <ClientsList />
          </Col>
        )
      }
    </Grid>
  )
}

export default ClientsPage