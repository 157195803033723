import * as Yup from 'yup'

Yup.addMethod(Yup.mixed, 'requiredPatchable', function (msg, def) {
  return this.when('$isPatch', (isPatch, fieldSchema) => {
    if (isPatch) return fieldSchema.notRequired()
    if (def !== undefined) return fieldSchema.required(msg).default(def)
    return fieldSchema.required(msg)
  })
})

Yup.addMethod(Yup.mixed, 'emptyToNull', function () {
  return this.transform((value) => value === '' || value === 0 || isNaN(value) ? null : value)
})

export const loginValidation = Yup.object({
  email: Yup.string()
    .required('Please enter an email address')
    .min(5, 'Email must be at least 5 characters')
    .max(254, 'Email must be less than 254 characters')
    .email('Invalid email address')
    .default(''),
  password: Yup.string()
    .required('Please enter a password')
    .default('')
})

export const clientValidation = Yup.object({
  userId: Yup.number()
    .integer('Must be an integer')
    .positive('Must be a positive number')
    .required('Please provide a user ID'),
  name: Yup.string()
    .required('Please enter a name')
    .max(100, 'Must be 100 characters or less')
    .min(3, 'Must be at least 3 characters')
    .default(''),
  addr1: Yup.string()
    .required('Please enter an address')
    .max(100, 'Must be 100 characters or less')
    .min(3, 'Must be at least 3 characters')
    .default(''),
  addr2: Yup.string()
    .max(100, 'Must be 100 characters or less')
    .default(null)
    .nullable(),
  city: Yup.string()
    .required('Please enter a city or town')
    .max(100, 'Must be 100 characters or less')
    .min(3, 'Must be at least 3 characters')
    .default(''),
  county: Yup.string()
    .max(100, 'Must be 100 characters or less')
    .default(null)
    .nullable(),
  pcode: Yup.string()
    .required('Please enter a postcode')
    .max(15, 'Must be 15 characters or less')
    .min(3, 'Must be at least 3 characters')
    .default(''),
  tel: Yup.string()
    .max(20, 'Must be 20 characters or less')
    .min(3, 'Must be at least 3 characters')
    .default(null)
    .nullable(),
  email: Yup.string()
    .min(5, 'Email must be at least 5 characters')
    .max(254, 'Email ust be less than 254 characters')
    .email('Invalid email address')
    .default(null)
    .nullable(),
})

export const jobValidation = Yup.object({
  clientId: Yup.number()
    .integer('Must be an integer')
    .positive('Must be a positive number')
    .requiredPatchable('Please provide a client ID'),
  userId: Yup.number()
    .integer('Must be an integer')
    .positive('Must be a positive number')
    .requiredPatchable('Please provide a user ID'),
  regNo: Yup.string()
    .max(30, 'Must be 30 characters or less')
    .min(3, 'Must be at least 3 characters')
    .matches(/^[a-zA-Z0-9 ]+$/)
    .requiredPatchable('Please enter a reg no', ''),
  hasCarCover: Yup.boolean()
    .requiredPatchable('', false),
  isKeyFobWritten: Yup.boolean()
    .requiredPatchable('', false),
  hasMot: Yup.boolean()
    .requiredPatchable('', false),
  isTaxed: Yup.boolean()
    .requiredPatchable('', false),
  mileageIn: Yup.number()
    .emptyToNull()
    .typeError('Please enter a mileage')
    .integer('Must be an integer')
    .min(-1, 'Must be a positive number')
    .nullable(),
  //.requiredPatchable('Please enter the mileage'),
  mileageOut: Yup.number()
    .emptyToNull()
    .integer('Must be an integer')
    .min(-1, 'Must be a positive number')
    .default(null)
    .nullable(),
  status: Yup.mixed()
    .oneOf(['inProgress', 'complete'])
    .default('inProgress')
})

export const jobItemValidation = Yup.object({
  headId: Yup.number()
    .integer('Must be an integer')
    .positive('Must be a positive number')
    .nullable(),
  partId: Yup.number()
    .typeError('Please choose a part')
    .required('Please choose a part')
    .integer('Must be an integer')
    .positive('Must be a positive number')
    .default(null),
  id: Yup.number()
    .integer('Must be an integer')
    .positive('Must be a positive number')
    .nullable(),
  qty: Yup.number()
    .typeError('Please enter a qty')
    .required('Please enter a qty')
    .integer('Must be an integer')
    .positive('Must be a positive number')
    .default(1),
})

export const kitValidation = Yup.object({
  code: Yup.string()
    .required('Please enter a code')
    .max(100, 'Must be 100 characters or less')
    .min(3, 'Must be at least 3 characters')
    .default(''),
  name: Yup.string()
    .required('Please enter a name')
    .max(100, 'Must be 100 characters or less')
    .min(3, 'Must be at least 3 characters')
    .default('')
})

export const kitItemValidation = Yup.object({
  headId: Yup.number()
    .integer('Must be an integer')
    .positive('Must be a positive number')
    .default(null)
    .nullable(),
  partId: Yup.number()
    .integer('Must be an integer')
    .positive('Must be a positive number')
    .required('Please choose a part')
    .default(null)
    .nullable(),
  id: Yup.number()
    .integer('Must be an integer')
    .positive('Must be a positive number')
    .default(null)
    .nullable(),
  qty: Yup.number()
    .transform((val) => val === '' ? null : val)
    .typeError('Invalid qty')
    .required('Please enter a qty')
    .positive('Must be a positive number')
    .default(1)
})

export const kitWithItemsValidation = kitValidation.shape({
  items: Yup.array().of(kitItemValidation).default([kitItemValidation.cast({})])
})

export const postcodeValidation = Yup.object({
  lookup: Yup.string()
    .required('Please enter a postcode')
    .matches(/^[\w\ ]+$/)
    .default('')
})

export const tokenValidation = Yup.object({
  name: Yup.string()
    .required('Please enter a name')
    .max(30, 'Must be 30 characters or less')
    .min(3, 'Must be at least 3 characters')
    .matches(/^[a-zA-Z0-9 ]+$/)
    .default('')
})

export const userValidation = Yup.object({
  name: Yup.string()
    .required('Please enter a name')
    .max(100, 'Must be 100 characters or less')
    .min(3, 'Must be at least 3 characters')
    .default(''),
  email: Yup.string()
    .transform((v) => v === '' ? null : v)
    .min(5, 'Email must be at least 5 characters')
    .max(254, 'Email ust be less than 254 characters')
    .email('Invalid email address')
    .nullable()
    .default(''),
  password: Yup.string()
    .transform((v) => v === '' ? null : v)
    .min(8, 'Must be at least 8 characters')
    .max(64, 'Must be 64 characters or less')
    .matches(/[0-9]/, 'Must contain at least one number')
    .matches(/[a-z]/, 'Must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Must contain at least one uppercase letter')
    .nullable()
    .default('')
})