import { useOutletContext, useParams } from 'react-router-dom'

import { Grid, Col } from 'components/gsys-ui'
import JobDetails from 'components/job/JobDetails'
import PartsList from 'components/job/PartsList'
import { DocumentTextIcon } from '@heroicons/react/24/outline'

const JobPage = () => {
  const params = useParams()
  const { setTitle, setTitleIcon } = useOutletContext()
  setTitle('Job EJW' + (parseInt(params.jobId) + 1000))
  setTitleIcon(DocumentTextIcon)

  return (
    <Grid>
      <Col width={4}>
        <div className="space-y-8">
          <JobDetails jobId={params.jobId} />
        </div>
      </Col>
      <Col width={8}>
        <PartsList jobId={params.jobId} />
      </Col>
    </Grid>
  )
}

export default JobPage
