import { Field, ErrorMessage } from 'formik'

const Checkbox = ({ name, label }) => (
  <div className="w-full">
    <div className="flex space-x-2">
      <Field
        id={name}
        type="checkbox"
        name={name}
        className="w-5 h-5 border-gray-300 transition-colors"
      />
      <label for={name} className="text-sm font-semibold select-none">{label}</label>
    </div>

    <div className="text-sm text-red-600">
      <ErrorMessage name={name} />
    </div>
  </div >
)

export default Checkbox