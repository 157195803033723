import { useEffect, useMemo, useState } from 'react'
import { DocumentTextIcon } from '@heroicons/react/24/outline'

import Table from 'components/ui/Table'
import DateFormatted from 'components/clients/DateFormatted'
import StatusBadge from 'components/clients/StatusBadge'
import JobStatusBadge from 'components/clients/JobStatusBadge'
import { Card, Grid, Col, MultiButton } from 'components/gsys-ui'
import { formatJobId } from 'util/helpers'
import { useOutletContext } from 'react-router-dom'
import { useJobs } from 'util/queries'

const ActiveJobsPage = () => {
  const { setTitle, setTitleIcon } = useOutletContext()
  setTitle('Jobs')
  setTitleIcon(DocumentTextIcon)

  return (
    <Grid>
      <Col width={12}>
        <ActiveJobsCard />
      </Col>
    </Grid>
  )
}

const ActiveJobsCard = () => {
  const [filter, setFilter] = useState('inProgress')
  const [{ pageIndex, pageSize }, setPagination] = useState({ pageIndex: 0, pageSize: 10 })
  const { isLoading, isError, data: res, error } = useJobs(filter, pageIndex)

  const handleChangeFilter = (val) => {
    setFilter(val)
    setPagination({ pageIndex: 0, pageSize: 10 })
  }

  if (isLoading) return null
  if (isError) return <div>{JSON.stringify(error)}</div>
  if (res.error) return <div>{JSON.stringify(res)}</div>

  return (
    <Card>
      <Card.Title Icon={DocumentTextIcon}>
        <div>Active jobs</div>
        <MultiButton
          value={filter}
          options={[
            { label: 'In progress', value: 'inProgress' },
            { label: 'All jobs', value: '' }
          ]}
          onChange={handleChangeFilter}
        />
      </Card.Title>
      <Card.Body>
        <ActiveJobsTable
          data={res.data}
          currentPage={pageIndex}
          onPageChange={setPagination}
          totalRowsCount={res.totalRowsCount}
        />
      </Card.Body>
    </Card>
  )
}

const ActiveJobsTable = (props) => {
  const columns = useMemo(() => [
    {
      header: 'Date',
      accessorKey: 'createdAt',
      cellSize: 90,
      cell: ({ row }) => <DateFormatted date={row.original.createdAt} />
    },
    {
      header: 'Job no.',
      accessorKey: 'id',
      cell: ({ row }) => <Table.Link url={`/jobs/${row.original.id}`}>{formatJobId(row.original.id)}</Table.Link>
    },
    {
      header: 'Reg no.',
      accessorKey: 'regNo'
    },
    {
      header: 'Client',
      accessorKey: 'client',
      cell: ({ row }) => <Table.Link url={`/clients/${row.original.clientId}`}>{row.original.clientName}</Table.Link>
    },
    {
      header: 'Part count',
      accessorKey: 'itemCount',
      cell: ({ row }) => row.original.itemCount || 0
    },
    {
      header: 'Value',
      accessorKey: 'totalValue',
      cell: ({ row }) => row.original.totalValue ? `£${row.original.totalValue.toFixed(2)}` : '£0.00'
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cellSize: 96,
      cell: ({ row }) => <JobStatusBadge status={row.original.status} />,
      disableSortBy: true
    },
    {
      header: '',
      id: 'hasMot',
      cellSize: 46,
      cellPad: false,
      cell: ({ row }) => <img
        className="w-7 h-7"
        src={row.original.hasMot ? '/img/motOn.png' : '/img/motOff.png'}
        alt="Has MOT?"
      />
    },
    {
      header: '',
      id: 'isKeyFobWritten',
      cellSize: 46,
      cellPad: false,
      cell: ({ row }) => <img
        className="w-7 h-7"
        src={row.original.isKeyFobWritten ? '/img/keyfobOn.png' : '/img/keyfobOff.png'}
        alt="Has MOT?"
      />
    },
    {
      header: '',
      id: 'isTaxed',
      cellSize: 46,
      cellPad: false,
      cell: ({ row }) => <img
        className="w-7 h-7"
        src={row.original.isTaxed ? '/img/taxOn.png' : '/img/taxOff.png'}
        alt="Has MOT?"
      />
    },
    {
      header: '',
      id: 'hasCarCover',
      cellSize: 46,
      cellPad: false,
      cell: ({ row }) => <img
        className="w-7 h-7"
        src={row.original.hasCarCover ? '/img/coverOn.png' : '/img/coverOff.png'}
        alt="Has MOT?"
      />
    }
  ])

  console.log(props.data)
  const data = useMemo(() => props.data, [props.data])

  return (
    <Table
      cols={columns}
      data={data}
      isPaginated={true}
      currentPage={props.currentPage}
      onPageChange={props.onPageChange}
      totalRowsCount={props.totalRowsCount}
    />
  )
}


export default ActiveJobsPage