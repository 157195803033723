import { useQueryClient, useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

import axios from 'util/axios'

export const useLogin = () => {
  return useMutation({
    mutationFn: (body) => axios.post('/auth/password', body)
  })
}

export const useLogout = () => {
  const navigate = useNavigate()

  return useMutation({
    mutationFn: () => axios.post('/auth/logout'),
    onSuccess: () => navigate('/login')
  })
}

export const useAddClient = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body) => axios.post('/clients', body),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ['clients'], exact: true })
    }
  })
}

export const useEditClient = (clientId) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body) => axios.put(`/clients/${clientId}`, body),
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['clients'], exact: true }),
        queryClient.invalidateQueries({ queryKey: ['clients', clientId] })
      ])
    }
  })
}

export const useDeleteClient = (clientId) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () => axios.delete(`/clients/${clientId}`),
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['clients'], exact: true }),
        queryClient.invalidateQueries({ queryKey: ['clients', clientId] })
      ])
    }
  })
}

export const useAddJob = (clientId) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body) => axios.post('/jobs', body),
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['jobs'], exact: true }),
        queryClient.invalidateQueries({ queryKey: ['jobs', 'inProgress'] }),
        queryClient.invalidateQueries({ queryKey: ['clients', clientId] })
      ])
    }
  })
}

export const useUpdateJob = (jobId) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body) => axios.put(`/jobs/${jobId}`, body),
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['jobs'], exact: true }),
        queryClient.invalidateQueries({ queryKey: ['jobs', 'inProgress'] }),
        queryClient.invalidateQueries({ queryKey: ['jobs', jobId] })
      ])
    }
  })
}

export const useDeleteJob = (jobId) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () => axios.delete(`/jobs/${jobId}`),
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['jobs'], exact: true }),
        queryClient.invalidateQueries({ queryKey: ['jobs', 'inProgress'] }),
        queryClient.invalidateQueries({ queryKey: ['jobs', jobId] })
      ])
    }
  })
}

export const useAddJobItem = (jobId) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body) => axios.post(`/jobs/${jobId}/items`, body),
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['jobs'], exact: true }),
        queryClient.invalidateQueries({ queryKey: ['jobs', 'inProgress'] }),
        queryClient.invalidateQueries({ queryKey: ['jobs', jobId] })
      ])
    }
  })
}

export const useUpdateJobItem = (jobId, partId) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body) => axios.put(`/jobs/${jobId}/items/${partId}`, body),
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['jobs'], exact: true }),
        queryClient.invalidateQueries({ queryKey: ['jobs', 'inProgress'] }),
        queryClient.invalidateQueries({ queryKey: ['jobs', jobId] })
      ])
    }
  })
}

export const useDeleteJobItem = (jobId, partId) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () => axios.delete(`/jobs/${jobId}/items/${partId}`),
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['jobs'], exact: true }),
        queryClient.invalidateQueries({ queryKey: ['jobs', 'inProgress'] }),
        queryClient.invalidateQueries({ queryKey: ['jobs', jobId] })
      ])
    }
  })
}

export const useCreateUser = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body) => axios.post('/users', body),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ['users'], exact: true })
    }
  })
}

export const useUpdateUser = (userId) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body) => axios.put(`/users/${userId}`, body),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ['users'], exact: true })
    }
  })
}

export const useDeleteUser = (userId) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () => axios.delete(`/users/${userId}`,),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ['users'], exact: true })
    }
  })
}

export const useCreateToken = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body) => axios.post('/tokens', body),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ['tokens'], exact: true })
    }
  })
}

export const useDeleteToken = (tokenId) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () => axios.delete(`/tokens/${tokenId}`),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ['tokens'], exact: true })
    }
  })
}

export const useAddKit = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body) => axios.post('/kits', body),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ['kits'], exact: true })
    }
  })
}

export const useEditKit = (kitId) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body) => axios.put(`/kits/${kitId}`, body),
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['kits'], exact: true }),
        queryClient.invalidateQueries({ queryKey: ['kits', kitId] })
      ])
    }
  })
}

export const useDeleteKit = (kitId) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () => axios.delete(`/kits/${kitId}`),
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['kits'], exact: true }),
        queryClient.invalidateQueries({ queryKey: ['kits', kitId] })
      ])
    }
  })
}

export const useModifyAlerts = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body) => axios.patch(`/parts`, body),
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['parts'] })
      ])
    }
  })
}

export const useUpdateSetting = (key) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body) => axios.put(`/settings/${key}`, body),
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: ['settings'] })
      ])
    }
  })
}