import { useContext } from 'react'

import { Error, Row, Spacer, Footer } from 'components/ui/form/Form'
import { ModalContext } from 'context/ModalContext'
import { InputFormik, Button } from 'components/gsys-ui'
import PartInput from './PartInput'
import { useFormikContext } from 'formik'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'

const AddPartForm = ({ isSubmitting, handleSubmit, error, isEdit }) => {
  const modal = useContext(ModalContext)
  const { errors } = useFormikContext()

  return (
    <>
      <Error>{error}</Error>
      <Row>
        <div className="w-full">
          <div className="flex items-end space-x-2 w-full">
            <div className="flex-1 min-w-0">
              <PartInput name="partId" label="Part" isDisabled={!!isEdit} />
            </div>
            <div className="flex-none">
              <InputFormik
                noError={true}
                className="!w-[60px]"
                label="Qty."
                name="qty"
              />
            </div>
          </div>
          <div className="text-sm text-red-600">
            {errors?.partId || errors?.qty}
          </div>
        </div>
      </Row>
      <Footer>
        <Button variant="white" onClick={modal.close}>
          <XMarkIcon className="mr-1 w-5 h-5" />
          Cancel
        </Button>
        <Button variant="green" onClick={handleSubmit} loading={isSubmitting}>
          <CheckIcon className="mr-1 w-5 h-5" />
          Submit
        </Button>
      </Footer>
    </>
  )
}

export default AddPartForm