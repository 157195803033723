import { useContext } from 'react'

import { Form } from 'components/ui/form/Form'
import JobForm from 'components/clients/JobForm'
import { jobValidation } from 'util/validations'
import { useAddJob } from 'hooks/mutations'
import { DocumentTextIcon } from '@heroicons/react/24/outline'
import { ModalContext } from 'context/ModalContext'
import { Card } from 'components/gsys-ui'

const AddJobModal = ({ initial }) => {
  const modal = useContext(ModalContext)
  console.log(initial)
  const mutation = useAddJob(initial.clientId)

  return (
    <Card className="m-auto">
      <Card.Title Icon={DocumentTextIcon}>Add a job</Card.Title>
      <Card.Body>
        <Form
          validation={jobValidation}
          initial={initial}
          mutation={mutation}
          FormComp={JobForm}
          onSubmit={modal.close}
        />
      </Card.Body>
    </Card>
  )
}

export default AddJobModal