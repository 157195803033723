import axios from 'axios'

import history from './history'
import { BASE_URL } from './constants'

const instance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true
})

instance.interceptors.response.use(
  (res) => res.data,
  (err) => {
    if (err.response.status === 401) history.push('/login')
    throw err
  }
)

export default instance