
import { PencilSquareIcon, TrashIcon, UserIcon } from '@heroicons/react/24/outline'

import { useContext } from 'react'
import { ModalContext } from 'context/ModalContext'
import EditClientModal from './EditClientModal'
import DeleteModal from 'components/ui/modal/DeleteModal'
import { useDeleteClient } from 'hooks/mutations'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { Card, Grid, Col, Button } from 'components/gsys-ui'
import { useClient } from 'util/queries'

const ClientDetails = ({ clientId }) => {
  const modal = useContext(ModalContext)
  const { setTitle, setTitleIcon } = useOutletContext()
  const deleteMutation = useDeleteClient(clientId)
  const { isLoading, isError, data: res, error } = useClient(clientId)
  const navigate = useNavigate()

  if (isLoading) return null
  if (isError) return <div>{JSON.stringify(error)}</div>

  if (res.error) {
    navigate('/clients')
    return <div>{JSON.stringify(res)}</div>
  }

  setTitle(res.data.name)
  setTitleIcon(UserIcon)

  const addr = [res.data.addr1, res.data.addr2, res.data.city, res.data.county, res.data.pcode].filter((el) => !!el)

  return (
    <Card>
      <Card.Title Icon={UserIcon}>
        <div>Client details</div>
        <div>
          <Button
            variant="rounded"
            onClick={() => modal.set(
              <EditClientModal
                initial={{
                  addr1: res.data.addr1,
                  addr2: res.data.addr2,
                  city: res.data.city,
                  county: res.data.county,
                  email: res.data.email,
                  id: res.data.id,
                  name: res.data.name,
                  pcode: res.data.pcode,
                  tel: res.data.tel,
                  userId: res.data.userId
                }}
              />
            )}
          >
            <PencilSquareIcon className="w-5 h-5" />
          </Button>
          <Button
            variant="rounded"
            onClick={() => modal.set(
              <DeleteModal
                name="client"
                doDelete={deleteMutation.mutateAsync}
              />
            )}
          >
            <TrashIcon className="w-5 h-5" />
          </Button>
        </div>
      </Card.Title>
      <Card.Body>
        <Grid gap={8}>
          <Col width={3}><div className="font-bold">Name</div></Col>
          <Col width={9}>{res.data.name}</Col>
          <Col width={3}><div className="font-bold">Email</div></Col>
          <Col width={9}>{res.data.email}</Col>
          <Col width={3}><div className="font-bold">Tel</div></Col>
          <Col width={9}>{res.data.tel}</Col>
          <Col width={3}><div className="font-bold">Address</div></Col>
          <Col width={9}>
            {
              addr.map((line, ind) => <div key={ind}>{line}</div>)
            }
          </Col>
        </Grid>
      </Card.Body>
    </Card>
  )
}

export default ClientDetails