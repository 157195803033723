
import { useContext, useMemo, useState } from 'react'
import { DocumentTextIcon, PlusIcon } from '@heroicons/react/24/outline'

import { ModalContext } from 'context/ModalContext'
import Table from 'components/ui/Table'
import AddJobModal from 'components/clients/AddJobModal'
import JobStatusBadge from 'components/clients/JobStatusBadge'
import DateFormatted from 'components/clients/DateFormatted'
import { Card, Button } from 'components/gsys-ui'
import { formatJobId } from 'util/helpers'
import { useClientJobs } from 'util/queries'

const ClientJobs = ({ clientId }) => {
  const modal = useContext(ModalContext)
  const [{ pageIndex, pageSize }, setPagination] = useState({ pageIndex: 0, pageSize: 9 })
  const { isLoading, isError, data: res, error } = useClientJobs(clientId, pageIndex, pageSize)

  if (isLoading) return null
  if (isError) return <div>{JSON.stringify(error)}</div>
  if (res.error) return <div>{JSON.stringify(res)}</div>

  return (
    <Card>
      <Card.Title Icon={DocumentTextIcon}>
        <div>Client jobs</div>
        <Button onClick={() => modal.set(<AddJobModal initial={{ userId: 1, clientId }} />)}>
          <PlusIcon className="mr-1 w-5 h-5" />
          Add job
        </Button>
      </Card.Title>
      <Card.Body>
        <ClientJobsTable
          data={res.data}
          currentPage={pageIndex}
          onPageChange={setPagination}
          totalRowsCount={res.totalRowsCount}
        />
      </Card.Body>
    </Card>
  )
}

const ClientJobsTable = (props) => {
  const columns = useMemo(() => [
    {
      header: 'Date',
      accessorKey: 'dateCreated',
      cellSize: 90,
      cell: ({ row }) => <DateFormatted date={row.original.createdAt} />
    },
    {
      header: 'Job no.',
      accessorKey: 'id',
      cell: ({ row }) => <Table.Link url={`/jobs/${row.original.id}`}>{formatJobId(row.original.id)}</Table.Link>
    },
    {
      header: 'Reg no.',
      accessorKey: 'regNo'
    },
    {
      header: 'Creator',
      id: 'creator',
      accessorFn: (row) => row.userName,
      //cell: ({ row }) => <Table.Link url={`/users/${row.original.userId}`}>{row.original.userName}</Table.Link>
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cellSize: 96,
      cell: ({ row }) => <JobStatusBadge status={row.original.status} />,
      disableSortBy: true
    }
  ])

  const data = useMemo(() => props.data, [props.data])

  return (
    <Table
      cols={columns}
      data={data}
      isPaginated={true}
      currentPage={props.currentPage}
      onPageChange={props.onPageChange}
      totalRowsCount={props.totalRowsCount}
    />
  )
}

export default ClientJobs