import { useState } from 'react'
import { UserIcon, ArrowRightOnRectangleIcon, UsersIcon, HomeIcon, Cog8ToothIcon, DocumentTextIcon, WrenchIcon, ListBulletIcon, ArchiveBoxIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { Link, Outlet, useLocation } from 'react-router-dom'

import Avatar from 'components/ui/Avatar'
import { Button } from 'components/gsys-ui'

import styles from './Layout.module.scss'
import { useLogout } from 'hooks/mutations'

const nav = [
  {
    url: '/jobs',
    title: 'Jobs',
    Icon: DocumentTextIcon
  },
  {
    url: '/clients',
    title: 'Clients',
    Icon: UsersIcon
  },
  {
    url: '/parts',
    title: 'Parts',
    Icon: WrenchIcon
  },
  {
    url: '/kits',
    title: 'Part kits',
    Icon: ArchiveBoxIcon
  },
  {
    url: '/settings',
    title: 'Settings',
    Icon: Cog8ToothIcon
  }
]

const Layout = ({ children }) => {
  const [title, setTitle] = useState(null)
  const [TitleIcon, setTitleIcon] = useState(null)
  const logout = useLogout()
  const location = useLocation()

  return (
    <>
      <header className="fixed top-0 left-0 right-0 h-[70px] pl-[200px] bg-topbar z-40 flex justify-between items-center">
        {
          title && TitleIcon && (
            <div className="flex items-center p-3 h-full text-white">
              <TitleIcon className="mr-2 w-7 h-7" />
              <div className="text-lg font-semibold">{title}</div>
            </div>
          )
        }
        <div className="p-3">
          <Button onClick={() => logout.mutate()}>
            <ArrowRightOnRectangleIcon className="mr-1 w-5 h-5" />
            Log out
          </Button>
        </div>
      </header>

      <div className="fixed top-0 left-0 bottom-0 w-[200px] bg-white z-50 border-r border-r-gray-200 shadow-md space-y-4">
        <div className="h-[70px] flex items-center justify-center">
          <div className={styles.logo} />
        </div>
        {/*<div className="flex flex-col items-center">
          <Avatar src="/avatar.png" size={55} />
          <div>Nathan Finch</div>
          <div className="flex space-x-1">
            <Button variant="rounded">
              <UserIcon className="w-5 h-5" />
            </Button>
            <Button variant="rounded" onClick={() => logout.mutate()}>
              <ArrowRightOnRectangleIcon className="w-5 h-5" />
            </Button>
          </div>
      </div>*/}
        <div>
          {
            nav.map((link, id) => (
              <NavLink key={id} {...link} />
            ))
          }
        </div>
      </div>

      <div className="w-full pl-[200px] pt-[70px]">
        <div className="p-3">
          <Outlet context={{ setTitle, setTitleIcon }} />
        </div>
      </div>
    </>
  )
}

const NavLink = ({ title, Icon, url, active = false }) => (
  <Link to={url}>
    <div className={clsx(
      'flex items-center px-3 py-3 bg-white transition-colors cursor-pointer hover:bg-gray-100',
      {
        'bg-gray-100': active
      }
    )}>
      <Icon className="mr-4 w-7 h-7" />
      <div>{title}</div>
    </div>
  </Link>
)

export default Layout