import { useState } from 'react'
import clsx from 'clsx'
import { ChevronLeftIcon, ChevronRightIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Button } from 'components/gsys-ui'
import { useNavigate } from 'react-router-dom'

const ResultsDrawer = ({ data, closeDrawer }) => {
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const maxPage = Math.floor(data.length / 5)
  const paginatedData = data.slice(page * 5, (page * 5) + 5)
  const peoplePerson = data.length === 1 ? 'person' : 'people'

  const prevPage = () => {
    if (page > 0) setPage(page - 1)
  }

  const nextPage = () => {
    if (page < maxPage) setPage(page + 1)
  }

  const handleClick = (id) => {
    navigate(`/clients/${id}`)
    closeDrawer()
  }

  return (
    <div className="absolute w-full bg-white rounded border shadow-lg border-grey-400 top-[100%] left-0 mt-1 flex flex-col space-y-3 p-4 z-50">
      <div className="text-lg font-bold">{data.length} {peoplePerson} found</div>
      <table className="mx-[-16px]">
        <thead>
          <Trh>
            <Th>Name</Th>
            <Th>Email</Th>
            <Th>Tel</Th>
            <Th className="w-[90px]"></Th>
          </Trh>
        </thead>
        <tbody>
          {
            paginatedData.map((row, ind) => (
              <Tr key={ind} onClick={() => handleClick(row.id)}>
                <Td>{row.name}</Td>
                <Td>{row.email}</Td>
                <Td>{row.tel}</Td>
                <Td>
                  <div className="flex justify-end items-center text-sm font-semibold">
                    <span>View</span> <ChevronRightIcon className="ml-2 w-5 h-5" />
                  </div>
                </Td>
              </Tr>
            ))
          }
        </tbody>
      </table>
      {
        maxPage > 0 ? (
          <PaginationControls
            prevPage={prevPage}
            nextPage={nextPage}
            page={page}
            maxPage={maxPage}
          />
        ) : null
      }
      <div className="flex justify-end">
        <Button onClick={closeDrawer}>
          <XMarkIcon className="mr-1 w-5 h-5" />
          Cancel
        </Button>
      </div>
    </div>
  )
}

const PaginationControls = ({ prevPage, nextPage, page, maxPage }) => (
  <div className="flex">
    <div className="flex flex-1 justify-start">
      <Button variant="white" onClick={prevPage}><ChevronLeftIcon className="w-5 h-5" /></Button>
    </div>
    <div className="flex flex-1 justify-center items-center text-sm font-semibold">
      Page {page + 1}/{maxPage + 1}
    </div>
    <div className="flex flex-1 justify-end">
      <Button variant="white" onClick={nextPage}><ChevronRightIcon className="w-5 h-5" /></Button>
    </div>
  </div>
)

const Trh = ({ children, ...props }) => <tr {...props} className={clsx('font-semibold bg-gray-100 cursor-pointer select-none', props.className)}>{children}</tr>
const Tr = ({ children, ...props }) => <tr {...props} className={clsx('cursor-pointer select-none hover:bg-gray-50 border-b border-gray-100', props.className)}>{children}</tr>
const Td = ({ children, ...props }) => <td {...props} className={clsx('p-4', props.className)}>{children}</td>
const Th = ({ children, ...props }) => <td {...props} className={clsx('px-4 py-2', props.className)}>{children}</td>

export default ResultsDrawer