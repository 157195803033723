import { useContext } from 'react'

import { Form } from 'components/ui/form/Form'
import ClientForm from 'components/clients/ClientForm'
import { clientValidation } from 'util/validations'
import { useEditClient } from 'hooks/mutations'
import { UserPlusIcon } from '@heroicons/react/24/outline'
import { ModalContext } from 'context/ModalContext'
import { Card } from 'components/gsys-ui'

const EditClientModal = ({ initial }) => {
  const modal = useContext(ModalContext)
  const mutation = useEditClient(initial.id)

  const handleSubmit = (res) => {
    modal.close()
  }

  return (
    <Card className="m-auto">
      <Card.Title Icon={UserPlusIcon}>Edit a client</Card.Title>
      <Card.Body>
        <Form
          validation={clientValidation}
          initial={initial}
          mutation={mutation}
          FormComp={ClientForm}
          onSubmit={handleSubmit}
        />
      </Card.Body>
    </Card>
  )
}

export default EditClientModal