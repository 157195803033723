import { useState, useEffect } from 'react'
import { useFormikContext, useField } from 'formik'

import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { Button, Spinner } from 'components/gsys-ui'
import * as c from 'util/constants'
import * as req from 'util/request'

const PostcodeSearchField = (props) => {
  const [isSearching, setIsSearching] = useState(false)
  const [lookupResults, setLookupResults] = useState(false)
  const { setFieldValue, setFieldTouched } = useFormikContext()
  const [field, meta, helpers] = useField(props)

  useEffect(() => {
    if (!field.value || field.value.trim() === '') {
      setIsSearching(false)
      return
    }

    const lookupPostcode = async () => {
      const res = await req.get(c.BASE_URL + '/postcode', { lookup: field.value })
      setIsSearching(false)

      if (res.error) {
        helpers.setError(res.error.message)
      } else if (res.data.Message) {
        helpers.setError('Postcode not found')
      } else {
        helpers.setError(null)
        setLookupResults(res.data)
      }
    }

    let isCurrent = true

    if (isSearching === true && field.value !== '' && isCurrent === true) {
      lookupPostcode()
    }

    return () => {
      isCurrent = false
    }
  }, [isSearching, setFieldValue, props.name]);

  useEffect(() => {
    setLookupResults(false)
  }, [field.value])

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      setIsSearching(true)
    }
  }

  const setAddress = (id) => {
    if (lookupResults === false || !lookupResults.addresses) return
    const addr = lookupResults.addresses[id]
    setFieldValue(props.name, field.value.toUpperCase())
    setFieldValue(props.fields?.addr1 || 'addr1', addr[0])
    setFieldValue(props.fields?.addr2 || 'addr2', addr[1])
    setFieldValue(props.fields?.city || 'city', addr[3])
    setFieldValue(props.fields?.county || 'county', addr[4])
    setFieldTouched(props.fields?.addr1 || 'addr1', false, false)
    setFieldTouched(props.fields?.addr2 || 'addr2', false, false)
    setFieldTouched(props.fields?.city || 'city', false, false)
    setFieldTouched(props.fields?.county || 'county', false, false)
  }

  const isOpen = lookupResults !== false && lookupResults.addresses

  return (
    <div className="w-full">
      <div className="text-sm font-semibold">
        Postcode lookup
      </div>
      <div className="flex relative w-full">
        <input
          className="flex-1 p-2 w-full text-sm rounded rounded-r-none border border-r-0 border-gray-300 transition-colors focus:outline-none focus:border-blue-400"
          onKeyUp={handleKeyUp}
          {...props}
          {...field}
        />
        <Button
          className="flex-none !rounded-l-none"
          onClick={() => setIsSearching(true)}
        >
          {
            isSearching ? (
              <Spinner size={20} color="rgb(255,255,255)" />
            ) : (
              <MagnifyingGlassIcon className="w-5 h-5" />
            )
          }
        </Button>
        {
          isOpen && (
            <ResultsDrawer
              data={lookupResults.addresses}
              setAddress={setAddress}
              closeDrawer={() => setLookupResults(false)}
            />
          )
        }
      </div>
      {
        !!meta.touched && !!meta.error && (
          <div className="text-sm text-red-600">{meta.error}</div>
        )
      }
    </div>
  )
}

const ResultsDrawer = ({ data, setAddress, closeDrawer }) => {
  const handleClick = (id) => {
    setAddress(id)
    closeDrawer()
  }

  return (
    <div className="absolute w-full bg-white rounded border shadow-lg border-grey-400 top-[100%] left-0 mt-1 flex flex-col max-h-[400px] overflow-y-scroll z-50">
      {
        data.map((row, ind) => {
          const address = row.filter((s) => s.trim() !== '').join(', ')

          return (
            <div
              key={ind}
              className="px-4 py-2 text-sm cursor-pointer hover:bg-gray-100"
              onClick={() => handleClick(ind)}
            >
              {address}
            </div>
          )
        })
      }
    </div>
  )
}

export default PostcodeSearchField