import { createContext, useState } from 'react'

import useModal from 'hooks/useModal'

export const ModalContext = createContext()

export const ModalContextProvider = ({ children }) => {
  const { isOpen, close, content, set } = useModal()

  return (
    <ModalContext.Provider value={{ isOpen, close, content, set }}>
      {children}
    </ModalContext.Provider>
  )
}