
import { useContext } from 'react'

import { Error, Row, Spacer, Footer, VSpacer } from 'components/ui/form/Form'
import Checkbox from 'components/ui/form/Checkbox'
import { Button, DropdownFormik, InputFormik } from 'components/gsys-ui'
import { ModalContext } from 'context/ModalContext'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'

const JobForm = ({ isSubmitting, handleSubmit, error, isEdit }) => {
  const modal = useContext(ModalContext)

  return (
    <>
      <Error>{error}</Error>
      <Row>
        <InputFormik name="regNo" label="Reg no." />
        <VSpacer />
      </Row>
      <Row>
        <InputFormik name="mileageIn" label="Mileage in (optional)" />
        <InputFormik name="mileageOut" label="Mileage out (optional)" />
      </Row>
      <Row>
        {
          isEdit && (
            <DropdownFormik
              className="w-full"
              label="Status"
              name="status"
              options={[
                { label: 'In progress', value: 'inProgress' },
                { label: 'Complete', value: 'complete' }
              ]}
            />
          )
        }
      </Row>
      <Spacer />
      <Row>
        <Checkbox name="isKeyFobWritten" label="Key fob written?" />
        <Checkbox name="hasCarCover" label="Car cover?" />
        <Checkbox name="hasMot" label="MOT?" />
        <Checkbox name="isTaxed" label="Taxed?" />
      </Row>
      <Spacer />
      <Footer>
        <Button variant="white" onClick={modal.close}>
          <XMarkIcon className="mr-1 w-5 h-5" />
          Cancel
        </Button>
        <Button variant="green" onClick={handleSubmit} loading={isSubmitting}>
          <CheckIcon className="mr-1 w-5 h-5" />
          Submit
        </Button>
      </Footer>
    </>
  )
}

export default JobForm