import { useContext } from 'react'

import { Form } from 'components/ui/form/Form'
import { jobItemValidation } from 'util/validations'
import { useUpdateJobItem } from 'hooks/mutations'
import { WrenchIcon } from '@heroicons/react/24/outline'
import { ModalContext } from 'context/ModalContext'
import { Card } from 'components/gsys-ui'
import PartForm from './PartForm'

const EditPartModal = ({ initial }) => {
  const modal = useContext(ModalContext)
  const mutation = useUpdateJobItem(initial.headId, initial.partId)

  return (
    <Card className="m-auto">
      <Card.Title Icon={WrenchIcon}>Edit a part</Card.Title>
      <Card.Body>
        <Form
          validation={jobItemValidation}
          initial={initial}
          mutation={mutation}
          FormComp={PartForm}
          onSubmit={modal.close}
          isEdit={true}
          formProps={{ isEdit: true }}
        />
      </Card.Body>
    </Card>
  )
}

export default EditPartModal