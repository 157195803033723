import * as req from 'util/request'
import * as c from 'util/constants'
import { useEffect, useState } from 'react'

const useAuth = () => {
  const [isLoading, setLoading] = useState(true)
  const [isAuthed, setAuthed] = useState(null)

  useEffect(() => {
    (async () => {
      const res = await checkAuth()
      setAuthed(res)
      setLoading(false)
    })()
  }, [])

  const checkAuth = async () => {
    try {
      const res = await req.get(c.BASE_URL + '/auth/check')
      if (res.error) return false
      return true
    } catch (e) {
      console.log(e)
      return false
    }
  }

  return { isAuthed, isLoading }
}

export default useAuth