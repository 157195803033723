import { useState, useEffect } from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

import ResultsDrawer from 'components/clients/ResultsDrawer'
import useDebounce from 'hooks/useDebounce'
import { Spinner, ButtonInput } from 'components/gsys-ui'
import { useClients } from 'util/queries'

const ClientSearchField = ({ setClient }) => {
  const [searchField, setSearchField] = useState('')
  const searchDebounced = useDebounce(searchField, 200)
  const [isOpen, setIsOpen] = useState(false)

  const { isLoading, isError, data: res, error } = useClients(
    searchDebounced, null, null,
    { enabled: searchDebounced.length >= 3 }
  )

  useEffect(() => {
    if (isOpen === false) {
      if (isLoading === false && res && searchField.length >= 3) {
        setIsOpen(true)
      }
    } else {
      if (searchField.length < 3) {
        setIsOpen(false)
      }
    }
  }, [searchField, isLoading, res])

  const handleCancel = () => {
    setIsOpen(false)
    setSearchField('')
  }

  const data = res?.data ?? []

  return (
    <div className="relative w-full">
      <ButtonInput
        placeholder="Type to search for a client..."
        value={searchField}
        onChange={(e) => setSearchField(e.target.value)}
      >
        {
          isLoading ? (
            <Spinner size={20} color="rgb(255,255,255)" />
          ) : (
            <MagnifyingGlassIcon className="w-5 h-5" />
          )
        }
      </ButtonInput>

      {
        isOpen && (
          <ResultsDrawer
            data={data}
            closeDrawer={handleCancel}
          />
        )
      }
    </div>
  )
}

export default ClientSearchField