import { WrenchIcon } from '@heroicons/react/24/outline'
import { Grid, Col } from 'components/gsys-ui'
import KitsList from 'components/parts/KitsList'
import PartsList from 'components/parts/PartsList'
import SelectedPartsList from 'components/parts/SelectedPartsList'
import { useState } from 'react'
import { useOutletContext } from 'react-router-dom'

const PartsPage = () => {
  const { setTitle, setTitleIcon } = useOutletContext()
  setTitle('Parts')
  setTitleIcon(WrenchIcon)

  const [selectedParts, setSelectedParts] = useState([])

  const selectPart = (part) => {
    const selectedPartIds = selectedParts.map((p) => p.id)

    if (Array.isArray(part)) {
      const partsToAdd = part.filter((p) => !selectedPartIds.includes(p.id))

      setSelectedParts([
        ...selectedParts,
        ...partsToAdd
      ])
    } else {
      if (selectedPartIds.includes(part.id)) return

      setSelectedParts([
        ...selectedParts,
        part
      ])
    }
  }

  const deselectPart = (part) => {
    const newSelectedParts = Array.isArray(part) ? (
      selectedParts.filter((p) => !part.map((p2) => p2.id).includes(p.id))
    ) : (
      selectedParts.filter((p) => p.id !== part.id)
    )

    setSelectedParts(newSelectedParts)
  }

  const togglePart = (part) => {
    if (selectedParts.filter((p) => p.id === part.id).length > 0) {
      deselectPart(part)
    } else {
      selectPart(part)
    }
  }

  const clearParts = () => setSelectedParts([])

  return (
    <Grid>
      <Col width={12}>
        <PartsList
          selectedParts={selectedParts}
          selectPart={selectPart}
          deselectPart={deselectPart}
          togglePart={togglePart}
        />
      </Col>
      <Col width={6}>
        <SelectedPartsList
          selectedParts={selectedParts}
          deselectPart={deselectPart}
          clearParts={clearParts}
        />
      </Col>
    </Grid>
  )
}

export default PartsPage