import { useState } from 'react'

const useModal = () => {
  const [isOpen, setOpen] = useState(false)
  const [content, setContent] = useState(null)

  const set = (content = null) => {
    setContent(content)
    setOpen(true)
  }

  const close = () => {
    setOpen(false)
    setContent(null)
  }

  return { isOpen, close, content, set }
}

export default useModal