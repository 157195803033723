import clsx from 'clsx'

import s from './Button.module.scss'
import Spinner from '../Spinner/Spinner'
import { forwardRef } from 'react'

const Button = forwardRef((props, ref) => {
  const variant = props.variant || 'blue'
  const disabled = props.disabled || props.loading
  const dark = ['white', 'gray'].includes(variant)

  return (
    <button
      {...props}
      ref={ref}
      className={clsx(
        s.button,
        s[variant],
        props.loading && s.loading,
        props.className
      )}
      disabled={disabled}
      onClick={props.onClick}
    >
      <div className={s.content}>
        {props.children}
      </div>
      {
        props.loading && (
          <div className={s.spinner}>
            <Spinner
              size={18}
              color={dark ? 'rgba(0,0,0,0.3)' : 'rgba(255,255,255,0.8)'}
            />
          </div>
        )
      }
    </button>
  )
})

export default Button