import { ArchiveBoxIcon } from '@heroicons/react/24/outline'
import { Grid, Col } from 'components/gsys-ui'
import KitsList from 'components/parts/KitsList'
import PartsList from 'components/parts/PartsList'
import { useOutletContext } from 'react-router-dom'

const PartKitsPage = () => {
  const { setTitle, setTitleIcon } = useOutletContext()
  setTitle('Part kits')
  setTitleIcon(ArchiveBoxIcon)

  return (
    <Grid>
      <Col width={12}>
        <KitsList />
      </Col>
    </Grid>
  )
}

export default PartKitsPage