import { useContext, useMemo } from 'react'
import { KeyIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'

import { ModalContext } from 'context/ModalContext'
import Table from 'components/ui/Table'
import CreateTokenModal from 'components/settings/CreateTokenModal'
import { Card, Button } from 'components/gsys-ui'
import DeleteModal from 'components/ui/modal/DeleteModal'
import { useDeleteToken } from 'hooks/mutations'
import BarcodeModal from 'components/parts/BarcodeModal'
import { BiBarcodeReader } from 'react-icons/bi'
import { useTokens } from 'util/queries'

const TokensList = () => {
  const { isLoading, isError, data: res, error } = useTokens()
  const modal = useContext(ModalContext)

  if (isLoading) return null
  if (isError) return <div>{JSON.stringify(error)}</div>
  if (res.error) return <div>{JSON.stringify(res)}</div>

  return (
    <Card>
      <Card.Title Icon={KeyIcon}>
        <div>Scanner API Tokens</div>
        <div>
          <Button onClick={() => modal.set(<CreateTokenModal />)}>
            <PlusIcon className="mr-1 w-5 h-5" />
            Create token
          </Button>
        </div>
      </Card.Title>
      <Card.Body>
        <TokensTable data={res.data} />
      </Card.Body>
    </Card>
  )
}

const TokensTable = (props) => {
  const modal = useContext(ModalContext)
  const columns = useMemo(() => [
    {
      header: 'Name',
      accessorKey: 'name'
    },
    {
      header: 'Token',
      accessorKey: 'token'
    },
    {
      header: '',
      id: 'barcode',
      cellSize: 20,
      cellPad: false,
      cell: ({ row }) => (
        <Button variant="rounded" onClick={() => modal.set(<BarcodeModal code={row.original.token} />)}>
          <BiBarcodeReader className="w-6 h-6 m-[-4px] text-gray-700" />
        </Button>
      )
    },
    {
      header: '',
      id: 'delete',
      accessorKey: 'delete',
      disableSortBy: true,
      cellPad: false,
      cellSize: 20,
      cell: ({ row }) => <TokenDeleteButton tokenId={row.original.id} />
    }
  ])

  const data = useMemo(() => props.data, [props.data])

  return (
    <Table cols={columns} data={data} />
  )
}

const TokenDeleteButton = ({ tokenId }) => {
  const modal = useContext(ModalContext)
  const deleteMutation = useDeleteToken(tokenId)

  return (
    <Button
      variant="rounded"
      onClick={() => modal.set(
        <DeleteModal
          name="token"
          doDelete={deleteMutation.mutateAsync}
        />
      )}
    >
      <TrashIcon className="w-5 h-5" />
    </Button>
  )
}

export default TokensList